import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apibaseURl } from '../../Function';
import { useParams } from 'react-router';
import { Spinner, Container, Alert } from 'react-bootstrap'; // Assuming you're using react-bootstrap

const Terms = () => {
  const [terms, setTerms] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  const fetchPages = async () => {
    try {
      const response = await axios.get(`${apibaseURl}page-about-web/${slug}`);
      console.log('response_view for page', response);

      if (response.status === 200 && response.data.success === true) {
        setTerms(response?.data?.data);
      } else {
        setError('No content found.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPages();
  }, [slug]);

  return (
    <>
      <Container>

        {loading ? (
          <div className="text-center my-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <Alert variant="danger" className="text-center">
            {error}
          </Alert>
        ) : (
            <>
               <h2 className="text-center my-4">{terms?.title}</h2>
               <div className="terms-content" dangerouslySetInnerHTML={{ __html: terms?.content }} />
            </>
        )}
      </Container>
    </>
  );
};

export default Terms;
