import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../adminContext/AccountProvider';
import { apibaseURl, tokengenerate, showErrorToast, getvalidationErr, showSuccessToast } from '../../../Function';
import axios from 'axios';

const EditFaq = () => {
  const { setIstoken } = useContext(AccountContext);
  const [inputs, setInputs] = useState({
    question: "",
    answer: "",
    status: ""
  });
  const navigate = useNavigate();
  const { id } = useParams();

  // single faq list api call here ===========>
  const fetchSingleFaq = async () => {
    try {
      const response = await axios.get(`${apibaseURl}faq/${id}`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status == 200 && response.data.success === true) {
        setInputs(response.data.data)
      } else {

      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  useEffect(() => {
    fetchSingleFaq();
  }, [])
  // add faq api call ==========================>
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    setIstoken(false)
    e.preventDefault();
    const faqdata = {
      question: inputs.question,
      answer: inputs.answer,
      status: inputs.status,
    }

    try {
      const response = await axios.put(`${apibaseURl}faq-update/${id}`, faqdata, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        }
      });

      if (response.status === 200 && response.data.success === true) {
        showSuccessToast(response.data.message);
        navigate("/admin/faq");
      } else {
        if (response.data.error && Object.entries(response.data.error).length > 0) {
          const errorMessages = getvalidationErr(response.data.error);
          showErrorToast(errorMessages);
        } else {
          showErrorToast(response.data.message);
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.status === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else {
        showErrorToast("Server Error.");
      }
    }
  };

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Edit Faqs</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/faq">Faqs List</Link>
                </li>
                <li className="breadcrumb-item active">Edit Faq</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h3 className="page-title">Edit Faqs</h3>
                          </div>
                          <div className="col-auto text-end float-end ms-auto download-grp">
                            <Link to="/admin/faq" className="btn btn-primary">
                              <i className="fa fa-angle-double-left" /> Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>
                          Question
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Question"
                          name="question"
                          value={inputs.question}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>
                          Answer
                          <span className="login-danger">*</span>
                        </label>
                        <textarea
                          className="textarea form-control"
                          id="exampleFormControlTextarea1"
                          cols={5}
                          rows={2}
                          name="answer"
                          value={inputs.answer}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 ">
                      <div className="form-group">
                        <label >Status Status</label>
                        <select className="select form-control" name="status" value={inputs.status} onChange={handleChange}>
                          <option value={"active"}>Active</option>
                          <option value={"inactive"}>Inactive</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="student-submit">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditFaq
