import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Layout from "./admin/component/layout/Layout";
import Login from "./admin/pages/Login";
import Dashboard from "./admin/pages/dashboard.js/Dashboard";
import Users from "./admin/pages/users/Users";
import Profile from "./admin/pages/account/Profile";
import UsersDetails from "./admin/pages/users/UsersDetails";
import Inquery from "./admin/pages/inquery/Inquery";
import Setting from "./admin/pages/global/Setting";
import Pages from "./admin/pages/pages/Pages";
import EditPages from "./admin/pages/pages/EditPages";
import EmailTemplate from "./admin/pages/emailTempalte/EmailTemplate";
import EditEmailTemplate from "./admin/pages/emailTempalte/EditEmailTemplate";
import ProtectRoute from "./admin/protect/ProtectRoute";
import OrderList from "./admin/pages/orders/OrderList";
import OrderInvoice from "./admin/pages/orders/OrderInvoice";
import AccountProvider from "./admin/adminContext/AccountProvider";
import Store from "./admin/pages/store/Store";
import Transition from "./admin/pages/transition/Transition";
import Reviews from "./admin/pages/review/Reviews";
import StoreSingleView from "./admin/pages/store/StoreSingleView";
import StoreWallet from "./admin/pages/store/StoreWallet";
import ScrollToTop from "./ScrollToTop";
import Faq from "./admin/pages/faq/Faq";
import AddFaq from "./admin/pages/faq/AddFaq";
import EditFaq from "./admin/pages/faq/EditFaq";
import DealboxList from "./admin/pages/delbox/DealboxList";
import FrontLayout from "./front/component/layout/FrontLayout";
import Home from "./front/pages/Home";
import axios from "axios";
import { apibaseURl, tokengenerate ,fileUpload } from './Function'
import {requestFromToken} from './utils/FirebaseUtils'
import Terms from "./front/pages/Terms";
import StoreHighlight from "./admin/pages/storeHighlight/StoreHighlight";
import AddStoreHighlight from "./admin/pages/storeHighlight/AddStoreHighlight";
import EditStoreHighlight from "./admin/pages/storeHighlight/EditStoreHighlight";


// global setting api call here ===>

export const GlobalExportdata = createContext();

function App() {

    const[globalSettingupdate,setglobalSettingupdate] = useState(true)
    const [globaldata, setGlobalData] = useState({})
    const [currency, setCurrency] = useState("")
    // fetch global setting api call here =====================>
    const fetchglobalsettings = async () => {
        try {
            const response = await axios.get(`${apibaseURl}settings-global`, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`
                }
            });
            if (response.status == 200 && response.data.success === true) {
                setGlobalData(response?.data?.data)
                setCurrency(response?.data?.data?.currency_symbal)
            } else {
                setGlobalData({})
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchglobalsettings();
    },[globalSettingupdate])

    useEffect(() => {
        const link = document.querySelector('link[rel="shortcut icon"]');
        // Ensure the element exists
          link.href = globaldata?.SiteFavicon ? fileUpload + globaldata.SiteFavicon : "";
      }, [globalSettingupdate,globaldata.SiteFavicon]);
    
      useEffect(() => {
        const title = globaldata.Sitetitle || "WinWin";
        document.title = title;
        document.getElementById("demo").innerHTML = title;
      }, [globalSettingupdate,globaldata.Sitetitle]);

    // fetch global setting api call here =====================>
    // fcm token update here  =====================>
    const fetchToken = async () => {
        try {
            const token = await requestFromToken();
            if (token) {
                // console.log("Token received:", token);
            }
        } catch (error) {
            // console.log('Error generating FCM token:', error);
        }
    };

    fetchToken();

    // google translate use



    return (
        <>
<div class="gtranslate_wrapper"></div>
            <ToastContainer />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <GlobalExportdata.Provider value={{ currency , globaldata ,setglobalSettingupdate }}>
                    <AccountProvider globaldata={globaldata}>
                        <ScrollToTop />
                        <Routes>
                            <Route path="/" element={<FrontLayout />}>
                                <Route path="/" element={<Home />} />
                                <Route path="/:slug" element={<Terms />} />


                            </Route>
                            <Route path="/admin" element={<ProtectRoute />}>
                                <Route path="/admin" element={<Layout />}>
                                    <Route path="/admin/dashboard" element={<Dashboard />} />

                                    <Route path="/admin/users" element={<Users />} />
                                    <Route path="/admin/profile" element={<Profile />} />
                                    <Route path="/admin/users/user-details/:id" element={<UsersDetails />} />

                                    <Route path="/admin/store" element={<Store />} />
                                    <Route path="/admin/store/store-detail/:id" element={<StoreSingleView />} />
                                    <Route path="/admin/store/store-wallet/:id" element={<StoreWallet />} />

                                    <Route path="/admin/support-help" element={<Inquery />} />
                                    <Route path="/admin/setting" element={<Setting />} />

                                    <Route path="/admin/pages" element={<Pages />} />
                                    <Route path="/admin/pages/edit-pages/:id" element={<EditPages />} />

                                    <Route path="/admin/email-temaplate" element={<EmailTemplate />} />
                                    <Route path="/admin/email-temaplate/edit-email-template/:id" element={<EditEmailTemplate />} />

                                    <Route path="/admin/orders" element={<OrderList />} />
                                    <Route path="/admin/orders/getorders/:id" element={<OrderInvoice />} />

                                    <Route path="/admin/transitions" element={<Transition />} />
                                    <Route path="/admin/review" element={<Reviews />} />

                                    <Route path="/admin/faq" element={<Faq />} />
                                    <Route path="/admin/faq/add-faq" element={<AddFaq />} />
                                    <Route path="/admin/faq/edit-faq/:id" element={<EditFaq />} />

                                    <Route path="/admin/store-highlight" element={<StoreHighlight />} />
                                    <Route path="/admin/store-highlight/add-store-highlight" element={<AddStoreHighlight />} />
                                    <Route path="/admin/store-highlight/edit-store-highlight/:id" element={<EditStoreHighlight/>} />

                                    <Route path="/admin/deal-box" element={<DealboxList />} />

                                </Route>
                            </Route>
                            <Route path="/admin/login" element={<Login />} />

                        </Routes>

                    </AccountProvider>
                </GlobalExportdata.Provider>
            </BrowserRouter>
       

        </>
    );
}

export default App;
