import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import screen_shot_img from '../../front/frontassest/images/screenshot-1.svg'
import screen_shot_img2 from '../../front/frontassest/images/screen-shot-2.svg'
import screen_shot_img3 from '../../front/frontassest/images/screen-shot-3.svg'
import screen_shot_img4 from '../../front/frontassest/images/screen-shot-4.svg'
import screen_shot_img5 from '../../front/frontassest/images/screen-shot-5.svg'
import Slider from "react-slick";


const ScreenShotSection = () => {

    const settings = {
        className: "center",
        centerMode: true,
        dots: true,
        infinite: true,
        autoplay: true,
        centerPadding: "10px",
        speed: 2000,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };



    return (
        <section className="section screen-shot">
            <div className="container">
                <div className="site-heading-box">
                    <h4 className="sub-heading">Screenshots</h4>
                    <h2 className="site-heading">App Screenshots</h2>
                </div>
                <div className="slide-container">
                    <Slider {...settings} className="item_slider_wrape">
                        <div>
                            <div className="item">
                                <div className="img-box">
                                    <img src={screen_shot_img} alt="Screenshot 1" className='width_check_bar' />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="img-box">
                                    <img src={screen_shot_img2} alt="Screenshot 2" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="img-box">
                                    <img src={screen_shot_img3} alt="Screenshot 3" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="img-box">
                                    <img src={screen_shot_img4} alt="Screenshot 4" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="img-box">
                                    <img src={screen_shot_img5} alt="Screenshot 5" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="img-box">
                                    <img src={screen_shot_img3} alt="Screenshot 3" />
                                </div>
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default ScreenShotSection;
