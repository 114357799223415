import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid,ResponsiveContainer } from 'recharts';

// Color array for the bars
const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const BarChartComponentRevenue = ({ dashboard }) => {
  // Prepare the data for the chart
  const data = [
    { name: "Today's", uv: dashboard.todayRevenue ?? 0 },
    { name: "Yesterday’s", uv: dashboard.YesterdayRevenue ?? 0 },
    { name: "This Month’s", uv: dashboard.thisMonthRevenue ?? 0 },
    { name: "This Year’s", uv: dashboard.thisYearRevenue ?? 0 },
  ];

  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
      ${x + width / 2}, ${y}
      C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
      Z`;
  };

  // Component for the triangular bar shape
  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, Math.max(...data.map(item => item.uv)) + 100]} /> {/* Adjust Y-axis domain */}
        <Bar dataKey="uv" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponentRevenue;
