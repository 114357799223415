import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { apibaseURl, userInfo, showErrorToast } from '../../Function'
import axios from 'axios';


export const AccountContext = createContext();

const AccountProvider = ({ children }) => {

  const [isLogin, setIsLogin] = useState(true)
  const [istoken, setIstoken] = useState(false)
  const [userData, setuserData] = useState("")
  const [dashboard, setDashboard] = useState({})
  const [isprofile, setisprofile] = useState(false)
  const [countryList, setCountryList] = useState([])
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem("winwinUser");
    return navigate("/admin/login", { replace: true });
  };

  const logoutadmin = {
    logout: logout,
  };

  const LoginRedirect = () => {
    localStorage.removeItem("winwinUser");
    return navigate("/admin/login", { replace: true });
  };

  useEffect(() => {
    if (istoken) {
      LoginRedirect();
    }
  }, [istoken]);

  useEffect(() => {
    if (!isLogin) {

      logout();
    }
  }, [isLogin]);



  // global setting api api call================>


  // dashboard api call================>
  const fetchCountryList = async () => {
    try {
      const response = await axios.get(`${apibaseURl}country-list`);
      if (response.status == 200 && response.data.success === true) {
        setCountryList(response?.data?.data)
      } else {
        setCountryList([])
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  useEffect(() => {
    fetchCountryList();
  }, [])
  // countyr list
  useEffect(() => {
    const loginUserProfile = userInfo();
    setuserData(loginUserProfile);
  }, [isprofile, isLogin])


  return (
    <>
      <AccountContext.Provider value={{
        logoutadmin,
        dashboard,
        setIsLogin,
        userData,
        setIstoken,
        setisprofile,
        countryList,
      }}>
        {children}
      </AccountContext.Provider>
    </>
  )
}

export default AccountProvider;