import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dummyUserImg from '../../assets/img/download.jpg';
import { CiLocationOn } from "react-icons/ci";
import axios from 'axios';
import { apibaseURl, fileUpload, showErrorToast, tokengenerate, loaderFunction, convertTo12HourFormat } from '../../../Function';
import Swal from 'sweetalert2';
import { AccountContext } from '../../adminContext/AccountProvider';
import { FaWallet } from "react-icons/fa6";
import { PiPercentFill } from "react-icons/pi";
import { BsClock } from "react-icons/bs";
import { GlobalExportdata } from '../../../App';


const Store = () => {
  const { setIstoken } = useContext(AccountContext);
  const { currency } = useContext(GlobalExportdata)
  const [storeList, setStoreList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [StoreData, handleGetCummission] = useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermEmail, setSearchsearchTermEmail] = useState("");
  const [searchTermVerified, setsearchTermVerified] = useState("");
  const [commonstoreList, setCommonstoreList] = useState([]);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // Number of items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages

  // fetch stores list =============================>
  const fetchStoresList = async (searchTerm = '', searchTermEmail = '', searchTermVerified = '', page = 1) => {
    try {
      const response = await axios.get(`${apibaseURl}findAllStores`, {
        params: {
          store_id: searchTerm || undefined, // Send undefined if the search term is empty
          store_email: searchTermEmail || undefined, // Send undefined if the email search term is empty
          is_verified: searchTermVerified || undefined, // Send undefined if the verification status is empty
          page,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      });

      if (response.status === 200 && response.data.success === true) {
        setStoreList(response?.data?.data);
        setTotalPages(response.data.totalPages || 1);
        setIsLoading(false)
      } else {
        setStoreList([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message);
        setIstoken(true);
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast('Server Error.');
      }
    } finally {
      setIsLoading(false)
    }
  };

  // useEffect(() => {
  //   fetchStoresList(searchTerm, searchTermEmail, searchTermVerified);
  // }, []);

  useEffect(() => {
    fetchStoresList(searchTerm, searchTermEmail, searchTermVerified, currentPage);
  }, [currentPage, pageSize])

  const handleSearch = () => {
    setCurrentPage(1);
    fetchStoresList(searchTerm, searchTermEmail, searchTermVerified, 1);
  };
  // delete api call =============================>
  const handledeletesubmit = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const responce = await handledelete(id);
        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            fetchStoresList();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Delete it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  const handledelete = async (id) => {
    const reponce = await axios.delete(`${apibaseURl}storeDelete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };

  // User Status Api Call =======================>
  // const handleStatusUpdatesubmit = async (id) => {
  //   try {
  //     const result = await Swal.fire({
  //       title: "Are you sure?",
  //       text: "You want to update this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, Update it!",
  //     });

  //     if (result.isConfirmed) {
  //       const responce = await handleStatus(id);
  //   
  //       if (responce.status === 200) {
  //         if (responce.data.success === true) {
  //           Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
  //           fetchusersList();
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Sorry you Can't Updatd it",
  //             text: responce.message,
  //           });
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status == 401 && error.response.data.success === false) {
  //       showErrorToast(error?.response?.data?.message)
  //       setIstoken(true)
  //     } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
  //       showErrorToast("Server Error.");
  //     }
  //   }
  // };
  // const handleStatus = async (id) => {
  //   const reponce = await axios.put(`${apibaseURl}customerStatusUpdate/${id}`, {},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${tokengenerate()}`,
  //       },
  //     }
  //   );
  //   return reponce;
  // };
  // verified status update api call =============================================>
  // Customer Status Api Call =======================>
  // const handleStatusUpdateverified = async (id, value) => {
  //   try {
  //     let result ;
  //     if(value =='rejected'){
  //       result = await Swal.fire({
  //         title: "Do you want to Update it",
  //         input: "text",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes, Update it!",
  //       });
  //     }else{
  //        result = await Swal.fire({
  //         title: "Do you want to Update it",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes, Update it!",
  //       });
  //     }

  //     if (result.isConfirmed) {
  //       const responce = await handleStatusverified(id, value);

  //       if (responce.status === 200) {
  //         if (responce.data.success === true) {
  //           Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
  //           fetchStoresList();
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Sorry you Can't Updatd it",
  //             text: responce.message,
  //           });
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status == 401 && error.response.data.success === false) {
  //       showErrorToast(error?.response?.data?.message)
  //       setIstoken(true)
  //     } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
  //       showErrorToast("Server Error.");
  //     }
  //   }
  // };
  const handleStatusUpdateverified = async (id, value, itemcheck) => {
    try {

      if (itemcheck == "rejected" && value === "rejected") {
        Swal.fire("Already Rejected", "This store has already been rejected.", "info");
        return; // Exit if already rejected
      }

      let result;
      let rejectionReason = null;

      // Check if status is being set to "rejected"
      if (value === "rejected") {
        result = await Swal.fire({
          title: "Do you want to update it?",
          text: "Please provide a reason for rejection.",
          input: "textarea",
          inputPlaceholder: "Enter rejection reason here...",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
        });

        if (result.isConfirmed && !result.value) {
          Swal.fire("Action Cancelled", "Rejection reason is required", "warning");
          return; // Exit if no rejection reason provided
        }
        rejectionReason = result.value; // Store the rejection reason
      } else {
        // For other statuses (not "rejected")
        result = await Swal.fire({
          title: "Do you want to update it?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
        });
      }

      // Proceed with the status update if confirmed
      if (result.isConfirmed) {
        const response = await handleStatusverified(id, value, rejectionReason);
        if (response.status === 200 && response.data.success) {
          Swal.fire("Status Updated!", "Your status has been updated.", "success");
          fetchStoresList();
        } else {
          Swal.fire({
            icon: "error",
            title: "Sorry, you can't update it",
            text: response.message,
          });
        }
      }
    } catch (error) {
      // Error handling
      if (error.response && error.response.status === 401 && !error.response.data.success) {
        showErrorToast(error?.response?.data?.message);
        setIstoken(true);
      } else if (error.response && error.response.status === 500 && !error.response.data.success) {
        showErrorToast("Server Error.");
      }
    }
  };


  const handleStatusverified = async (id, value, rejectionReason) => {
    const userData = {
      id: id,
      is_verified: value,
      rejected_reason: rejectionReason,
    };

    const reponce = await axios.put(`${apibaseURl}StoreStatusUpdate/${id}`, userData,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };


  const handleSearchChange = (event) => {
    const name = event.target.name
    if (name == "store_name") {
      setSearchTerm(event.target.value);
    } else if (name == "email") {
      setSearchsearchTermEmail(event.target.value);
    } else {
      setsearchTermVerified(event.target.value)
    }
    // Update search term as user types
  };
  // clewar filter
  const handleClear = () => {
    setSearchTerm("")
    setSearchsearchTermEmail("")
    setsearchTermVerified("")
    setCurrentPage(1); // Reset to first page on clear
    fetchStoresList("", "", "", 1);
  }

  // store list api call here ===================>
  const fetchstorecommonlist = async () => {
    try {
      const response = await axios.get(`${apibaseURl}store-common-list`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status === 200 && response.data.success === true) {
        setCommonstoreList(response?.data?.data)
        setIsLoading(false)
      } else {
        setCommonstoreList([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchstorecommonlist();
  }, [])


  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Store Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">All Stores</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center gy-4 responsive_bar_check">
                    <div className="col">
                      <h3 className="page-title">Stores List</h3>
                    </div>

                    <div className="col">
                      <select class="select form_input form-control" name='store_name' value={searchTerm} onChange={handleSearchChange}  >
                        <option selected="selected">Select Store</option>
                        {commonstoreList.map(store => (
                          <option key={store.id} value={store.id}>
                            {store.store_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col">
                      <input
                        type="text"
                        className="form_input form-control"
                        placeholder="Search By Email ..."
                        name="email"
                        value={searchTermEmail}  // Bind input value to searchTerm state
                        onChange={handleSearchChange}  //
                      />
                    </div>

                    <div className="col">
                      <select class="select form_input form-control" name='status' value={searchTermVerified} onChange={handleSearchChange}  >
                        <option selected="selected">Select Verified</option>
                        <option value={"Pending"}>Pending</option>
                        <option value={"Completed"}>Completed</option>
                        <option value={"Rejected"}>Rejected</option>
                      </select>
                    </div>

                    <div className='col'>
                      <button type='btn' onClick={handleSearch}
                        className="btn btn-sm btn-danger store_add_wallet_add">
                        <i className="feather-search" />&nbsp;
                        Search
                      </button> &nbsp;
                      <button type='btn' onClick={handleClear}
                        className="btn btn-sm btn-danger store_add_wallet_add_red">
                        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
                        Clear
                      </button>
                    </div>

                  </div>

                  <div className="row mt-2 gy-2">
                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label className='label_for_entryes'>
                          Show{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            className="custom-select custom-select-sm form-control form-control-sm"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading ?
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                  :
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Store</th>
                          <th>Wallet</th>
                          <th>Admin <br />Commission(%)</th>
                          <th>Verified</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {storeList.length > 0 ?
                          storeList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                <td className='td_check_all'>
                                  <span>
                                    <img src={item.store_logo ? fileUpload + item.store_logo : dummyUserImg} alt='store' className='store_image_check' />
                                  </span>
                                  <span className='support_store_check_fisrt'>

                                    <strong>{item.store_name}</strong><br />
                                    <CiLocationOn /> {item.store_address}<br />
                                    <BsClock className='fon_clock_set' /> {convertTo12HourFormat(item.open_time)} - {convertTo12HourFormat(item.close_time)}
                                  </span><br />
                                </td>
                                <td>{currency} {item.total_wallet_amount ?? "0"}</td>
                                <td>{item.store_commission_percentage !== null && item.store_commission_percentage !== undefined ? `${item.store_commission_percentage}%` : "NA"}</td>
                                <td>
                                  <div className="btn-group">
                                    <button type="button"
                                      className={`btn btn-sm btn_uppercase_for
                                         ${(item.is_verified == "pending") ? "btn-secondary"
                                          : (item.is_verified == "completed") ? "btn-success"
                                            : (item.is_verified == "rejected") ? "btn-danger"
                                              : ""}  dropdown-toggle`}
                                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    >
                                      {item.is_verified == "pending" ? "Pending"
                                        : item.is_verified == "completed" ? "Approved"
                                          : item.is_verified == "rejected" ? "Rejected"
                                            : ""}
                                    </button>
                                    <div className="dropdown-menu">
                                      <Link className="dropdown-item"
                                        onClick={() => handleStatusUpdateverified(item.id, "pending")}>
                                        Pending
                                      </Link>
                                      <Link className="dropdown-item"
                                        onClick={() => handleStatusUpdateverified(item.id, "completed")}>
                                        Approved
                                      </Link>

                                      <Link className="dropdown-item"
                                        onClick={() => handleStatusUpdateverified(item.id, "rejected", item.is_verified)}>
                                        Rejected
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td className='text-end'>

                                  {/* <div className="btn-group">
                                    <button type="button"
                                      className={`btn btn-sm btn_uppercase_for dropdown-toggle store_wallet_button_view`}
                                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    >
                                      Action
                                    </button>
                                    <div className="dropdown-menu">
                                      <Link to="/admin/store/store-wallet" className="dropdown-item">
                                        <FaWallet />&nbsp; wallet
                                      </Link>
                                      <Link to="" className="dropdown-item" onClick={() => handleGetCummission(item)}>
                                        <PiPercentFill />&nbsp; commission
                                      </Link>
                                      <Link to="/admin/store/store-detail" className="dropdown-item">
                                        <i className="feather-eye" />&nbsp; View
                                      </Link>
                                    </div>
                                  </div>&nbsp; */}

                                  <Link to={`/admin/store/store-detail/${item.id}`} className="btn btn-sm btn-danger store_add_wallet_add">
                                    <i className="feather-eye" />&nbsp; View

                                  </Link> &nbsp;

                                  <Link to="" className="btn btn-sm btn-danger store_add_wallet_add_red" onClick={() => handledeletesubmit(item.id)}>
                                    <i className="feather-trash-2" />&nbsp;
                                    Delete
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                          :
                          <tr>
                            <td colSpan="9" className="text-center">No Data Found!</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='for_data_structure_for_pagination'>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</a>
                  </li>
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, index) => {
                    const pageIndex = index + 1;
                    return (
                      <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                        <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                      </li>
                    );
                  })}
                  {totalPages > 5 && (
                    <li className="page-item">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  {totalPages > 5 && (
                    <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                      <a className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</a>
                    </li>
                  )}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Store;

