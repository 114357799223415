import React, { lazy, Suspense, useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import { Hourglass } from 'react-loader-spinner';

const loaderStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh"
};

const AdminCss = lazy(() => import('./AdminCss')); // Lazy-load AdminCss

const Layout = ({ userData }) => {
  const [showContent, setShowContent] = useState(false);
  const [handleToggleButton, setHandleToggleButton] = useState(false);
  const [slideNave, setSlideNave] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login"; 
  const handleToggleClick = () => {
    setHandleToggleButton(!handleToggleButton);
  };

  const handleSlidenaveChange = () => {
    setSlideNave(!slideNave);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const Loader = () => (
    <div style={loaderStyles}>
      <Hourglass
        visible={true}
        height="80"
        width="80"
        ariaLabel="hourglass-loading"
        colors={['#306cce', '#72a1ed']}
      />
    </div>
  );

  return (
    <>
      {/* Show loader for a minimum of 2 seconds */}
      {showContent ? (
        <Suspense fallback={<Loader />}>
          <AdminCss />
          <div className={`main-wrapper ${handleToggleButton ? "mini-sidebar" : ""} ${slideNave ? "slide-nav" : ""} ${handleToggleButton && isHovered ? "expand-menu" : ""}`}>
  

                {!isLoginPage &&     
                
                <Header 
              handleToggleClick={handleToggleClick} 
              handleSlidenaveChange={handleSlidenaveChange} 
              userData={userData} 
            />}
            <Sidebar 
            setIsHovered={setIsHovered} 
            handleSlidenaveChange={handleSlidenaveChange} 
            />
            <div className="page-wrapper">
              <Outlet />
            </div>
            <Footer />
          </div>
        </Suspense>
      ) : (
        <Loader />  // Show loader during 2-second delay
      )}
    </>
  );
};

export default Layout;
