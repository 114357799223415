import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AccountContext } from '../../adminContext/AccountProvider';
import { apibaseURl, tokengenerate, formatDateToCustomString, showErrorToast, loaderFunction } from '../../../Function';
import { GlobalExportdata } from '../../../App';

const Transition = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { setIstoken } = useContext(AccountContext);
  const { currency } = useContext(GlobalExportdata);
  const [pageSize,setPageSize] = useState(100); // Set your desired page size

  // Fetch all transactions with pagination
  const fetchAllTransactions = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apibaseURl}orderstransaction`, {
        params: { page, pageSize },
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      });

      if (response.status === 200 && response.data.success === true) {
        setTransactions(response.data.data);
        setTotalPages(response.data.totalPages); // Assuming your API returns totalPages
      } else {
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message);
        setIstoken(true);
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTransactions(currentPage);
  }, [currentPage,pageSize]);

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Transaction Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">All Transactions</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
              <div className="page-header">
                  <div className="row gy-2">
                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label className='label_for_entryes'>
                          Show{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            className="custom-select custom-select-sm form-control form-control-sm"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Order ID</th>
                          <th>Transaction ID</th>
                          <th>Amount</th>
                          <th>Currency</th>
                          <th>Transaction Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length > 0 ? (
                          transactions.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1 + (currentPage - 1) * pageSize}</td>
                              <td>{item.order_id}</td>
                              <td>{item.transaction_id}</td>
                              <td>{currency} {item.transaction_amt}</td>
                              <td>{item.transaction_currency}</td>
                              <td>{formatDateToCustomString(item.transaction_date)}</td>
                              <td>
                                <Link className="btn btn-sm btn-success text-white text_transform_uppercase">
                                  {item.status}
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={7}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div >
        <div className='row'>
          <div className='col-md-12'>
            <div className='for_data_structure_for_pagination'>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</a>
                  </li>
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, index) => {
                    const pageIndex = index + 1;
                    return (
                      <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                        <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                      </li>
                    );
                  })}
                  {totalPages > 5 && (
                    <li className="page-item">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  {totalPages > 5 && (
                    <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                      <a className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</a>
                    </li>
                  )}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transition;
