import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dummyUserImg from '../../assets/img/highlight.png';
import Swal from "sweetalert2";
import axios from "axios";
import { AccountContext } from '../../adminContext/AccountProvider';
import { apibaseURl, tokengenerate, showErrorToast, loaderFunction,fileUpload } from '../../../Function';

const StoreHighlight = () => {
  const { setIstoken } = useContext(AccountContext);
  const [highlightlist, sethighlightlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Usetr List Show Api ==========================>
  // category listing show api call ===================>
  const storeHighlightlist = async () => {
    try {
      const response = await axios.get(`${apibaseURl}store-highlight`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status === 200 && response.data.success === true) {
        sethighlightlist(response?.data?.data)
        setIsLoading(false)
      } else {
        sethighlightlist([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    storeHighlightlist();
  }, [])

  // page status update  api call here =================>
  const handleStatusUpdatesubmit = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to update this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      });

      if (result.isConfirmed) {
        const responce = await handleStatus(id);
        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
            storeHighlightlist();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Updatd it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  const handleStatus = async (id) => {
    const reponce = await axios.get(`${apibaseURl}store-highlight-status-update/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };

  // delete faq list =====================>

  const handledeletesubmit = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const responce = await handledelete(id);
        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            storeHighlightlist();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Delete it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  const handledelete = async (id) => {
    const reponce = await axios.delete(`${apibaseURl}store-highlight-delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };



  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Store Highlight Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">All Store Highlights</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Store Highlights</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <Link to="/admin/store-highlight/add-store-highlight" className="btn btn-primary">
                        <i className="fas fa-plus" /> Add Store Highlight
                      </Link>
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                )
                  :
                  (<div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Image</th>
                          <th>Title</th>
                          <th>Sort</th>
                          <th>Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {highlightlist.length > 0 ? (
                          highlightlist.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td><img src={item.image ? fileUpload + item.image : dummyUserImg} alt='img' className='product_image_check' /></td>
                                <td>{item.title}</td>
                                <td>{item.sort}</td>
                           
                                <td>
                                   <button type="button" className={`btn btn-sm ${item.status == "active" ? "btn btn-success"
                                    : "btn-danger"
                                    } `} onClick={() => handleStatusUpdatesubmit(item.id)}>

                                     {item.status == "active" ? "Active" : "Inactive"}
                                  </button>
                                </td>
                                <td className="text-end">
                                  <div className="actions">
                                    <Link to={`/admin/store-highlight/edit-store-highlight/${item.id}`} className="btn btn-sm bg-danger-light me-2" >
                                      <i className="feather-edit" />
                                    </Link>
                                    <Link to="" className="btn btn-sm bg-danger-light" onClick={() => handledeletesubmit(item.id)} >
                                      <i className="feather-trash-2" />
                                    </Link>
                                  </div>
                                </td>

                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={9} className='text-center'>No Data Found.</td>
                          </tr>
                        )
                        }

                      </tbody>
                    </table>
                  </div>
                  )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StoreHighlight;

