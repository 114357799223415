import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import profileimg from '../../assets/img/dummyUser.png'
import axios from 'axios';
import { apibaseURl, showErrorToast, tokengenerate, fileUpload,formatDateToCustomString } from '../../../Function'
import { AccountContext } from '../../adminContext/AccountProvider';

const UsersDetails = () => {
  const { setIstoken, currency } = useContext(AccountContext)
  const [userProfile, setUserProfile] = useState({})
  const [ordersList, setOrdersList] = useState([])
  const { id } = useParams();

  // single user profile show api call ==================================>

  const fetchSingleUser = async () => {
    try {
      const response = await axios.get(`${apibaseURl}CustomerSingleView/${id}`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });
 
      if (response.status == 200 && response.data.success === true) {
        setUserProfile(response?.data?.data?.user)
        setOrdersList(response?.data?.data?.orders)
      } else {
        setUserProfile({})
        setOrdersList([])
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  useEffect(() => {
    fetchSingleUser();
  }, [])


  const staticOrders = [
    {
      store_id: 'S001',
      store_details: { store_name: 'Σκλαβενίτης' },
      customer_id: 'C001',
      customer_details: { name: 'John Doe' },
      order_total: 100.00,
      order_date: '2024-09-23T10:00:00Z',
      payment_status: 'Completed',
      order_status: 'Completed',
    },
    {
      store_id: 'S002',
      store_details: { store_name: 'Σκλαβενίτης' },
      customer_id: 'C002',
      customer_details: { name: 'Jane Smith' },
      order_total: 150.00,
      order_date: '2024-09-22T10:00:00Z',
      payment_status: 'Completed',
      order_status: 'Completed',
    },
    {
      store_id: 'S003',
      store_details: { store_name: 'Σκλαβενίτης' },
      customer_id: 'C003',
      customer_details: { name: 'Alice Johnson' },
      order_total: 200.00,
      order_date: '2024-09-21T10:00:00Z',
      payment_status: 'Completed',
      order_status: 'Completed',
    },
  ];

  const isLoading = false;


  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Customer Details</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/users">Customers</Link>
                </li>
                <li className="breadcrumb-item active">Customer Details</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="profile-header">
              <div className="row align-items-center justify-content-center">
                <div className="col-auto profile-image profile-header-customer">
                  {userProfile?.image != null && userProfile?.image != undefined ?
                    <img
                      className="rounded-circle"
                      alt="User"
                      src={fileUpload + userProfile.image}
                    />
                    :
                    <img
                      className="rounded-circle"
                      alt="User"
                      src={profileimg}
                    />
                  }

                </div>
                <div className=" profile-user-info profile_user_info_check">
                  <span className="store_info">{userProfile.name}</span>
                  <span className="store_info_location">{userProfile.email}</span>
                  <span className="store_info_location">{userProfile.phone}</span>
                  {userProfile.address ?
                    <div className="user-Location">
                      <i className="fas fa-map-marker-alt" />{userProfile.address}
                    </div>
                    : ""}
                </div>
              </div>
            </div>

          </div>

          <div className='col-md-8'>
            <div className="profile-menu mb-5">
              <>
                <h5 class="card-title d-flex justify-content-between">
                  <span>Personal Details</span>

                </h5>
                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Name</p>
                  <p className="col-sm-9">{userProfile.name}</p>
                </div>

                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Email ID</p>
                  <p className="col-sm-9">
                    {userProfile.email}
                  </p>
                </div>
                <div className="row">
                  <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">Mobile</p>
                  <p className="col-sm-9">{userProfile.phone}</p>
                </div>
                <div className="row">
                  {userProfile.address ? (
                    <div className="row">
                      <p className="col-sm-3 text-muted text-sm-end mb-0">Address</p>
                      <p className="col-sm-9 mb-0">
                        {userProfile.address}
                      </p>
                    </div>
                  ) : null}
                </div>
              </>
            </div>
          </div>

          <div className='col-md-12 mt-5'>
            <div className="row">
            <h4 className="page-title mb-3">Order Summery</h4>
              <div className="col-sm-12">
                <div className="card card-table">
                  <div className="card-body">
          
                    {isLoading ? (
                      <div className='div_loader_set'>
                        {/* Your loader function can be added here */}
                        Loading...
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                          <thead className="student-thread">
                            <tr>
                              <th>S.No</th>
                              <th>Store</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Payment Status</th>
                              <th>Order Status</th>

                            </tr>
                          </thead>
                          <tbody>
                            {ordersList.length > 0 ? (
                              ordersList.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    Store Id : {item.store_id}<br />
                                    Store Name : {item.store_details.store_name}
                                  </td>
                                  <td>{currency} {item.order_total}</td>
                                  <td>{formatDateToCustomString(item.order_date)}</td>
                                  <td>
                                    <Link to="" className="btn btn-sm btn-success text-white">
                                      {item.payment_status}
                                    </Link> &nbsp;
                                  </td>
                                  <td>
                                    <Link to="" className="btn btn-sm btn-success text-white">
                                      {item.order_status}
                                    </Link> &nbsp;
                                  </td>

                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan={8}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersDetails;
