import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import dummyUserImg from '../../assets/img/download.jpg';
import axios from 'axios';
import { apibaseURl, showErrorToast, tokengenerate, loaderFunction, fileUpload } from '../../../Function'
import Swal from "sweetalert2";
import { AccountContext } from '../../adminContext/AccountProvider';
import { GlobalExportdata } from '../../../App';

const DealboxList = () => {
    const [delbox, setdelbox] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { setIstoken } = useContext(AccountContext);
    const { currency } = useContext(GlobalExportdata);
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [totalPages, setTotalPages] = useState(1);   // Total pages state
    const [pageSize, setPageSize] = useState(100);      // Page size

    // Dealbox list fetch function with pagination support
    const fetchdealboxlist = async (page = 1) => {
        try {
            const response = await axios.get(`${apibaseURl}deal-box-list`, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`
                },
                params: {
                    page,        // Current page
                    pageSize     // Page size
                }
            });

            if (response.status === 200 && response.data.success === true) {
                setdelbox(response?.data?.data);
                setTotalPages(response?.data?.totalPages);   // Set total pages
                setIsLoading(false);
            } else {
                setdelbox([]);
                setTotalPages(1);   // Reset to 1 page if no data
            }
        } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message);
                setIstoken(true);
            } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        } finally {
            setIsLoading(false);
        }
    }

    // Fetch data on component mount or page change
    useEffect(() => {
        fetchdealboxlist(currentPage);
    }, [currentPage,pageSize]);

    // Page change handler for pagination


    // Update status handler
    const handleStatusUpdatesubmit = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You want to update this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Update it!",
            });

            if (result.isConfirmed) {
                const response = await handleStatus(id);
                if (response.status === 200 && response.data.success === true) {
                    Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
                    fetchdealboxlist(currentPage);  // Refresh the current page
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message);
                setIstoken(true);
            } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        }
    };

    const handleStatus = async (id) => {
        const reponce = await axios.get(`${apibaseURl}deal-box-Status-update/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`,
                },
            }
        );
        return reponce;
    };
    // Delete handler
    const handledeletesubmit = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });

            if (result.isConfirmed) {
                const response = await handledelete(id);
                if (response.status === 200 && response.data.success === true) {
                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    fetchdealboxlist(currentPage);  // Refresh the current page
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message);
                setIstoken(true);
            } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        }
    };


    const handledelete = async (id) => {
        const reponce = await axios.delete(`${apibaseURl}deal-box-delete-admin/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`,
                },
            }
        );
        return reponce;
    };
    // Pagination UI


    return (
        <div className="content container-fluid">
            {/* Header */}
            <div className="page-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h3 className="page-title">Deal Box Manager</h3>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">All Deal Box</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Table */}


            <div className="row">
                <div className="col-sm-12">
                    <div className="card card-table">
                        <div className="card-body">
                            <div className="page-header">
                                <div className="row gy-2">
                                    <div className="col-sm-12 col-md-6">
                                        <div>
                                            <label className='label_for_entryes'>
                                                Show{" "}
                                                <select
                                                    value={pageSize}
                                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                >
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={500}>500</option>
                                                </select>{" "}
                                                entries
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isLoading ? (
                                <div className='div_loader_set'>
                                    {loaderFunction()}
                                </div>
                            ) : (
                                <div className="table-responsive">
                                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                        <thead className="student-thread">
                                            <tr>
                                                <th>S.No</th>
                                                {/* <th>Image</th> */}
                                                <th>Item Id</th>
                                                <th>Store Id</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>QTY</th>
                                                <th>Price</th>
                                                <th>Status</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {delbox.length > 0 ? (
                                                delbox.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                                        {/* <td>
                                                            <img
                                                                src={item.item_image ? fileUpload + item.item_image : dummyUserImg}
                                                                alt="img"
                                                                className="store_image_check"
                                                            />
                                                        </td> */}
                                                        <td>{item.id}</td>
                                                        <td>{item.store_id}</td>
                                                        <td>{item.user_id}</td>
                                                        <td>{item.item_name}</td>
                                                        <td>{item.qty}</td>
                                                        <td>{currency}{item.price}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className={`btn btn-sm ${item.status === "Active" ? "btn-success" : "btn-danger"}`}
                                                                onClick={() => handleStatusUpdatesubmit(item.id)}
                                                            >
                                                                {item.status === "Active" ? "Approved" : "Pending"}
                                                            </button>
                                                        </td>
                                                        <td className="text-end">
                                                            <button
                                                                className="btn btn-sm btn-danger store_add_wallet_add_red"
                                                                onClick={() => handledeletesubmit(item.id)}
                                                            >
                                                                <i className="feather-trash-2" />&nbsp;Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr className='text-center'>
                                                    <td colSpan={9}>No Data found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    {/* Pagination */}


                                </div>

                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='for_data_structure_for_pagination'>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</a>
                                </li>
                                {Array.from({ length: Math.min(5, totalPages) }).map((_, index) => {
                                    const pageIndex = index + 1;
                                    return (
                                        <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                                            <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                                        </li>
                                    );
                                })}
                                {totalPages > 5 && (
                                    <li className="page-item">
                                        <span className="page-link">...</span>
                                    </li>
                                )}
                                {totalPages > 5 && (
                                    <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                                        <a className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</a>
                                    </li>
                                )}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DealboxList;
