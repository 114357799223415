import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { FaBuilding } from "react-icons/fa";
import { TbArrowsLeftRight } from "react-icons/tb";
import { TbEyeStar } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { RiPagesLine } from "react-icons/ri";
import { FaBox } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { TbHighlightOff } from "react-icons/tb";

const Sidebar = ({ handleSlidenaveChange, setIsHovered }) => {

  useEffect(() => {
    const handleClick = () => {
      handleSlidenaveChange();
    };
    const ulElement = document.querySelector('#accordionExample');
    ulElement.addEventListener('click', handleClick);

    return () => {
      ulElement.removeEventListener('click', handleClick);
    };
  }, [handleSlidenaveChange]);


  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <ul id="accordionExample">
              <li>
                <NavLink to="/admin/dashboard">
                  <i className="fa fa-desktop" /> <span> Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/users">
                  <i class="fas fa-users"></i> <span> Customers</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/store">
                  <FaBuilding className='fw_font_check' />
                  <span> Store</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/deal-box">
                  <FaBox className='fw_font_check' />
                  <span> Store Dealbox</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/store-highlight">
                  <TbHighlightOff className='fw_font_check skiptranslate ' />
                  <span>Highlights</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/orders">
                  <i class="fa fa-cart-plus"></i>
                  <span> Orders</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/transitions">
                  <TbArrowsLeftRight className='fw_font_check' />
                  <span>Transaction</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/review">
                  <TbEyeStar className='fw_font_check' />
                  <span>Reviews</span>{" "}
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/pages">
                  <RiPagesLine className='fw_font_check' />
                  <span>Pages</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/faq">
                  <FaQuestionCircle className='fw_font_check' />
                  <span>Faq</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/admin/email-temaplate">
                  <i className="fa fa-envelope" />
                  <span>Mail Template</span>{" "}
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/support-help">
                  <BiSupport className='fw_font_check' />
                  <span>Supports</span>{" "}
                </NavLink>
              </li>

              <li>
                <NavLink to="/admin/setting">
                  <i class="fas fa-cog"></i>
                  <span>Settings</span>{" "}
                </NavLink>
              </li>

            </ul>
          </div>
        </div>

      </div>

    </>
  )
}

export default Sidebar;
