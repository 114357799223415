import React, { useEffect } from 'react';

const GTranslate = ({Authlogout}) => {
  useEffect(() => {
    // Set up GTranslate settings
    window.gtranslateSettings = {
      default_language: "en", // Default language
      detect_browser_language: false, // Enable browser language detection
      languages: ["en", "el"], // Supported languages (English and Greek)
      wrapper_selector: ".gtranslate_wrapper" // Wrapper selector for the dropdown
    };

    // Load the GTranslate script
    const script = document.createElement('script');
    script.src = "https://cdn.gtranslate.net/widgets/latest/dropdown.js"; // Use the dropdown.js for a dropdown interface
    script.defer = true; // Defer loading to prevent blocking
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      script.remove();
    };
  }, []);

  return <div className={`${Authlogout == 0 ? "gtranslate_wrapper":"gtranslateada_wrapper"}`}></div>; // Render the wrapper for GTranslate
};

export default GTranslate;
