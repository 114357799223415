import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../adminContext/AccountProvider';
import { apibaseURl, tokengenerate, showErrorToast, getvalidationErr, showSuccessToast,fileUpload } from '../../../Function';
import axios from 'axios';

const EditStoreHighlight = () => {
    const { setIstoken } = useContext(AccountContext);
    const [inputs, setInputs] = useState({
        title: "",
        image: "",
        sort: "",
        status: ""
    });
    const navigate = useNavigate();
    const {id} = useParams(); 
    // highlight image upload api call here ============>
    const handleFileUploadimage = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("image", file);
        try {
            const response = await axios.post(`${apibaseURl}upload`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${tokengenerate()}`,
                    },
                }
            );

            if (response.status === 200 && response.data.success === true) {
                setInputs((preInputs) => ({
                    ...preInputs,
                    image: response?.data?.data?.image
                }))
                showSuccessToast(response?.data?.message);
            } else {
                showErrorToast(response.data.msg);
            }
        } catch (error) {
            if (error.response && error.response.status == 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        }
    };

    // single store highlight api call ===>
        const fetchSinglestoreHeighlight = async () => {
            try {
              const response = await axios.get(`${apibaseURl}store-highlight/${id}`, {
                headers: {
                  Authorization: `Bearer ${tokengenerate()}`
                }
              });
        
              if (response.status == 200 && response.data.success === true) {
                setInputs(response.data.data)
              } else {
        
              }
            } catch (error) {
              if (error.response && error.response.status == 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
              } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
              }
            }
          }
        
          useEffect(() => {
            fetchSinglestoreHeighlight();
          }, [])
    // add faq api call ==========================>
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        setIstoken(false)
        e.preventDefault();
        const faqdata = {
            title: inputs.title,
            image: inputs.image,
            sort: inputs.sort,
            status: inputs.status,
        }

        try {
            const response = await axios.put(`${apibaseURl}store-highlight-update/${id}`, faqdata, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`,
                }
            });

            if (response.status === 200 && response.data.success === true) {
                showSuccessToast(response.data.message);
                navigate("/admin/store-highlight");
            } else {
                if (response.data.errors && Object.entries(response.data.errors).length > 0) {
                    const errorMessages = getvalidationErr(response.data.errors);
                    showErrorToast(errorMessages);
                } else {
                    showErrorToast(response.data.message);
                }
            }
        } catch (error) {
            if (error.response && error.response.status == 401 && error.response.data.status === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else {
                showErrorToast("Server Error.");
            }
        }
    };

    return (
        <>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Edit store highlight</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/admin/store-highlight">Store highlight List</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit store highlight</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="page-header">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <h3 className="page-title">Edit store highlight</h3>
                                                    </div>
                                                    <div className="col-auto text-end float-end ms-auto download-grp">
                                                        <Link to="/admin/store-highlight" className="btn btn-primary">
                                                            <i className="fa fa-angle-double-left" /> Back
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12">
                                            <div className="form-group local-forms">
                                                <label>
                                                    title
                                                    <span className="login-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="title"
                                                    name="title"
                                                    value={inputs.title}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            {inputs.image &&
                                                <img
                                                    className="rounded-circle"
                                                    alt="User"
                                                    src={fileUpload + inputs.image}
                                                    width="100px"
                                                    height="100px"
                                                />
                                            }
                                            <div className="form-group">
                                                <label>
                                                    Image
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="imageUpload"
                                                    onChange={handleFileUploadimage}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12">
                                            <div className="form-group local-forms">
                                                <label>
                                                    Sort
                                                    <span className="login-danger"></span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="sort"
                                                    name="sort"
                                                    value={inputs.sort}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 ">
                                            <div className="form-group">
                                                <label >Status Status</label>
                                                <select className="select form-control" name="status" value={inputs.status} onChange={handleChange}>
                                                    <option value={"active"}>Active</option>
                                                    <option value={"inactive"}>Inactive</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="student-submit">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditStoreHighlight;
