import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dummyUserImg from '../../assets/img/dummyUser.png';
import axios from 'axios';
import { apibaseURl, fileUpload, showErrorToast, tokengenerate, loaderFunction } from '../../../Function';
import Swal from 'sweetalert2';
import { AccountContext } from '../../adminContext/AccountProvider';

const Users = () => {
  const { setIstoken } = useContext(AccountContext);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermEmail, setSearchsearchTermEmail] = useState("");
  const [searchTermVerified, setsearchTermVerified] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100); // Number of items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages

  const fetchusersList = async (searchTerm = '', searchTermEmail = '', searchTermVerified = '', page = 1) => {
    try {
      const response = await axios.get(`${apibaseURl}customerViewAll`, {
        params: {
          name: searchTerm || undefined, // Send undefined if the search term is empty
          email: searchTermEmail || undefined, // Send undefined if the email search term is empty
          is_email_verification: searchTermVerified || undefined, // Send undefined if the verification status is empty
          page,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      });

      if (response.status === 200 && response.data.success === true) {
        setUserList(response?.data?.data);
        setTotalPages(response.data.totalPages || 1);
        setIsLoading(false);
      } else {
        setUserList([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message);
        setIstoken(true);
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast('Server Error.');
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    // Call the fetch function on component mount (with default search params)
    fetchusersList(searchTerm, searchTermEmail, searchTermVerified, currentPage);
  }, [currentPage, pageSize])

  const handleSearch = () => {
    setCurrentPage(1);
    fetchusersList(searchTerm, searchTermEmail, searchTermVerified, 1);
  };

  // delete api call =============================>
  const handledeletesubmit = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const responce = await handledelete(id);

        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            fetchusersList();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Delete it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  const handledelete = async (id) => {

    const reponce = await axios.delete(`${apibaseURl}customerDelete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };

  // User Status Api Call =======================>
  const handleStatusUpdatesubmit = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to update this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      });

      if (result.isConfirmed) {
        const responce = await handleStatus(id);

        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
            fetchusersList();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Updatd it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };
  const handleStatus = async (id) => {
    const reponce = await axios.get(`${apibaseURl}customerStatusUpdate/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };
  // verified status update api call =============================================>
  // Customer Status Api Call =======================>
  const handleStatusUpdateverified = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Do you want to Update it",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      });
      if (result.isConfirmed) {
        const responce = await handleStatusverified(id);

        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
            fetchusersList();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Updatd it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  const handleStatusverified = async (id) => {
    const reponce = await axios.put(`${apibaseURl}customerVerifiedStatusUpdate/${id}`, {},
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };

  const handleSearchChange = (event) => {
    const name = event.target.name
    if (name == "store_name") {
      setSearchTerm(event.target.value);
    } else if (name == "email") {
      setSearchsearchTermEmail(event.target.value);
    } else {
      setsearchTermVerified(event.target.value)
    }
  };


  // soft delete =======================>
    const handledeleteStatusUpdateverified = async (id) => {
      try {
        const result = await Swal.fire({
          title: "Do you want to Update it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
        });
        if (result.isConfirmed) {
          const responce = await handledeleteStatusverified(id);
          console.log("responce",responce)
          if (responce.status === 200) {
            if (responce.data.success === true) {
              Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
              fetchusersList();
            } else {
              Swal.fire({
                icon: "error",
                title: "Sorry you Can't Updatd it",
                text: responce.message,
              });
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status == 401 && error.response.data.success === false) {
          showErrorToast(error?.response?.data?.message)
          setIstoken(true)
        } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
          showErrorToast("Server Error.");
        }
      }
    };
    const handledeleteStatusverified = async (id) => {
      const reponce = await axios.get(`${apibaseURl}customerdeletestatusupdate/${id}`,
        {
          headers: {
            Authorization: `Bearer ${tokengenerate()}`,
          },
        }
      );
      return reponce;
    };
  

  const handleClear = () => {
    setSearchTerm("")
    setSearchsearchTermEmail("")
    setsearchTermVerified("")
    setCurrentPage(1); // Reset to first page on clear
    fetchusersList("", "", "", 1);
  }

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Customer Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">All Customers</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center gy-4 responsive_bar_check">
                    <div className="col">
                      <h3 className="page-title">Customers List</h3>
                    </div>

                    <div className="col">
                      <input
                        type="text"
                        className="form_input form-control"
                        placeholder="Search By Name ..."
                        name="store_name"
                        value={searchTerm}  // Bind input value to searchTerm state
                        onChange={handleSearchChange}  //
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form_input form-control"
                        placeholder="Search By Email ..."
                        name="email"
                        value={searchTermEmail}  // Bind input value to searchTerm state
                        onChange={handleSearchChange}  //
                      />
                    </div>
                    <div className="col">
                      <select class="select form_input form-control" name='status' value={searchTermVerified} onChange={handleSearchChange}  >
                        <option selected="selected">Select Verified</option>
                        <option value={"1"}>Verified</option>
                        <option value={"0"}>Unverified</option>
                      </select>&nbsp;
                    </div>
                    <div className='col'>
                      <button type='btn' onClick={handleSearch}
                        className="btn btn-sm btn-danger store_add_wallet_add">
                        <i className="feather-search" />&nbsp;
                        Search
                      </button> &nbsp;
                      <button type='btn' onClick={handleClear}
                        className="btn btn-sm btn-danger store_add_wallet_add_red">
                        <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="row mt-2 gy-2">
                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label className='label_for_entryes'>
                          Show{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            className="custom-select custom-select-sm form-control form-control-sm"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading ?
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                  :
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Verified</th>
                          <th>Status</th>
                          <th>Delete <br/>Account</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.length > 0 ?
                          userList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                <td><img src={item.image ? fileUpload + item.image : dummyUserImg} alt='img' className='product_image_check' /></td>
                                <td>{item.name}</td>
                                <td className='line_break_space'>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>
                                  <button type="button" className={`btn btn-sm ${item.is_email_verification == "1" ? "btn btn-success"
                                    : "btn-danger"
                                    } `} onClick={() => handleStatusUpdateverified(item.id)}>

                                    {item.is_email_verification == "0" ? "Unverified" : "Verified"}
                                  </button>
                                </td>

  
                                <td>
                                  <button type="button"
                                    className={`btn btn-sm ${item.status == "Active" ? "btn btn-success"
                                      : "btn-danger"
                                      } `}
                                    onClick={() => handleStatusUpdatesubmit(item.id)}
                                  >
                                    {item.status == "Active" ? "Active" : "Inactive"}
                                  </button>
                                </td>

                                <td>
                                  <button type="button" className={`btn btn-sm ${item.is_delete == "1" ? "btn btn-success"
                                    : "btn-danger"
                                    } `} onClick={() => handledeleteStatusUpdateverified(item.id)}>

                                    {item.is_delete == "0" ? "Yes" : "No"}
                                  </button>
                                </td>

                                <td className="text-end">
                                  <Link to={`/admin/users/user-details/${item.id}`} className="btn btn-sm btn-danger store_add_wallet_add">
                                    <i className="feather-eye" />&nbsp;
                                    View
                                  </Link> &nbsp;
                                  <Link to="" className="btn btn-sm btn-danger  store_add_wallet_add_red" onClick={() => handledeletesubmit(item.id)}>
                                    <i className="feather-trash-2" />&nbsp;
                                    Delete
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                          :
                          <tr>
                            <td colSpan="9" className="text-center">No Data Found!</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='for_data_structure_for_pagination'>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</a>
                  </li>
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, index) => {
                    const pageIndex = index + 1;
                    return (
                      <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                        <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                      </li>
                    );
                  })}
                  {totalPages > 5 && (
                    <li className="page-item">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  {totalPages > 5 && (
                    <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                      <a className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</a>
                    </li>
                  )}

                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;

