import React, { lazy, Suspense, useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router';
import { Hourglass } from 'react-loader-spinner';

const loaderStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
};


const FrontCss = lazy(() => import('./FrontCss'));
const FrontLayout = () => {

    const [showContent, setShowContent] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const Loader = () => (
        <div style={loaderStyles}>
         <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
            />
        </div>
    );

    return (
        <Suspense fallback={<Loader />}>
            {showContent ? (
                <>
                    <FrontCss />
                    <Header />
                    <Outlet />
                    <Footer />
                </>
            ) : (
                <Loader />
            )}
        </Suspense>
    );
}

export default FrontLayout;
