import React from 'react'
import {tokengenerate,userRole} from '../../Function'
import { Navigate, Outlet } from 'react-router'


const ProtectRoute = () => {
    return tokengenerate() !== false && userRole() === 'super_admin' ?  <Outlet/> : <Navigate to="/admin/login"/>
  }

export default ProtectRoute;
