import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import categoryimg from '../../assets/img/category_img.png'
import axios from 'axios';
import { apibaseURl,showErrorToast, tokengenerate,loaderFunction} from '../../../Function'
import Swal from "sweetalert2";
import { AccountContext } from '../../adminContext/AccountProvider';


const Pages = () => {
    const [Pages, setPage] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { setIstoken } = useContext(AccountContext)

    // category listing show api call ===================>
    const fetchpages = async () => {
        try {
            const response = await axios.get(`${apibaseURl}AllPages`, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`
                }
            });
            console.log("response view for Pages list api call", response)
            if (response.status === 200 && response.data.success === true) {
                setPage(response?.data?.data)
                setIsLoading(false)
            } else {
                setPage([]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchpages();
    }, [])

    // page status update  api call here =================>
    const handleStatusUpdatesubmit = async (id) => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You want to update this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Update it!",
            });

            if (result.isConfirmed) {
                const responce = await handleStatus(id);
                console.log("responce view for status update category", responce)
                if (responce.status === 200) {
                    if (responce.data.success === true) {
                        Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
                        fetchpages();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Sorry you Can't Updatd it",
                            text: responce.message,
                        });
                    }
                }
            }
        } catch (error) {
            if (error.response && error.response.status == 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        }
    };
    const handleStatus = async (id) => {
        const reponce = await axios.get(`${apibaseURl}updatePagestatusdata/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`,
                },
            }
        );
        return reponce;
    };

    return (
        <>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Pages Manager</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">All Pages</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card card-table">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Pages List</h3>
                                        </div>

                                    </div>
                                </div>
                                {isLoading ?
                                    <div className='div_loader_set'>
                                        {loaderFunction()}
                                    </div>
                                    :
                                    <div className="table-responsive">
                                        <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                            <thead className="student-thread">
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Title</th>
                                                    <th>Slug</th>
                                                    <th>Status</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Pages.length > 0 ?
                                                    Pages.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.slug}</td>
                                                                <td>
                                                                    <button type="button" className={`btn btn-sm ${item.status == "Active" ? "btn btn-success"
                                                                        : "btn-danger"
                                                                        } `} onClick={() => handleStatusUpdatesubmit(item.id)}>

                                                                        {item.status == "Active" ? "Active" : "Inactive"}
                                                                    </button>
                                                                </td>
                                                                <td className="text-end">
                                                                    <Link to={`/admin/pages/edit-pages/${item.id}`} className="btn btn-sm btn-danger store_add_wallet_add">
                                                                        <i className="feather-edit" />&nbsp;
                                                                        Edit
                                                                    </Link> &nbsp;
                                                                    <Link to="" className="btn btn-sm btn-danger store_add_wallet_add_red">
                                                                        <i className="feather-trash-2" />&nbsp;
                                                                        Delete
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr className='text-center'>
                                                      <td colSpan={9}>No Data found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pages;
