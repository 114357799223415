import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { apibaseURl, showErrorToast, tokengenerate, loaderFunction, formatDateToCustomString, showSuccessToast, getvalidationErr } from '../../../Function';
import { AccountContext } from '../../adminContext/AccountProvider';
import { FaWallet } from "react-icons/fa6";
import { GlobalExportdata } from '../../../App';


const StoreWallet = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { setIstoken } = useContext(AccountContext)
    const { currency } = useContext(GlobalExportdata)
    const [wallethistory, setwallethistory] = useState([])
    const [walletamount, setWalletamount] = useState(0)

    const { id } = useParams();
    const [inputs, setInputs] = useState({
        store_id: id,
        amount: "",
        transition_wallet_id: "",
        payment_mode: "",
        date: ""
    });

    // fetch wallet history here
    const fetchWallethistory = async () => {
        try {
            const response = await axios.get(`${apibaseURl}wallet-history/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`
                }
            });

            if (response.status === 200 && response.data.success === true) {
                setwallethistory(response?.data?.data?.store)
                setWalletamount(response?.data?.data?.wallet_amount)
                setIsLoading(false)
            } else {
                setwallethistory([]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchWallethistory();
    }, [])

    // add wallet history
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        setIstoken(false)
        e.preventDefault();
        const walletdata = {
            store_id: inputs.store_id,
            amount: inputs.amount,
            transition_wallet_id: inputs.transition_wallet_id,
            payment_mode: inputs.payment_mode,
            date: inputs.date,
        }
        try {
            const response = await axios.post(`${apibaseURl}wallet-add`, walletdata, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`,
                }
            });

            console.log("response", response)
            if (response.status === 200 && response.data.success === true) {
                showSuccessToast(response.data.message);
                fetchWallethistory()
                setInputs({
                    amount: "",
                    transition_wallet_id: "",
                    payment_mode: "",
                    date: ""
                })
            } else {
                if (response.data.errors && Object.entries(response.data.errors).length > 0) {
                    const errorMessages = getvalidationErr(response.data.errors);
                    showErrorToast(errorMessages);
                } else {
                    showErrorToast(response.data.message);
                }
            }
        } catch (error) {
            if (error.response && error.response.status == 401 && error.response.data.status === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else {
                showErrorToast("Server Error.");
            }
        }
    };


    return (
        <>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h3 className="page-title">Store Manager</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/admin/store">Store</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={`/admin/store/store-detail/${id}`}>Store Details</Link>
                                </li>

                                <li className="breadcrumb-item active">Store Wallet</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="card">
                    <div className="card-body">
                        <div class="row">
                            <div className='col-md-12 mb-4 flex_collum_update'>
                                <p className='wallet_amount_test_check'>
                                    <FaWallet /> Wallet : {currency} {walletamount ?? '0.00'}
                                </p>
                                <Link to={`/admin/store/store-detail/${id}`} className="btn btn-sm btn-danger store_add_wallet_add">
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp; back
                                </Link>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="form-group local-forms">
                                            <label>
                                                Wallet Amount
                                                <span className="login-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Wallet Amount"
                                                name='amount'
                                                value={inputs.amount}
                                                onChange={handleChange}

                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="form-group local-forms">
                                            <label>
                                                Transaction Id
                                                <span className="login-danger"></span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Transaction Id"
                                                name='transition_wallet_id'
                                                value={inputs.transition_wallet_id}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="form-group local-forms">
                                            <label>
                                                Payment Mode
                                            </label>
                                            <select className="select form-control" name='payment_mode' onChange={handleChange} value={inputs.payment_mode}>
                                                <option value="" disabled>Select Payment Mode</option>
                                                <option value="bank_transfer">Bank Transfer</option>
                                                <option value="credit_debit_card">Credit/Debit Card</option>
                                                <option value="cash">Cash</option>
                                                <option value="online">Online</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-md-3'>
                                        <div className="form-group local-forms">
                                            <label>
                                                Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name='date'
                                                value={inputs.date}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="student-submit">
                                            <button type="submit" className="btn btn-primary" disabled={!walletamount}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card card-table">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Store Wallet history</h3>
                                        </div>
                                    </div>
                                </div>
                                {isLoading ?
                                    <div className='div_loader_set'>
                                        {loaderFunction()}
                                    </div>
                                    :
                                    <div className="table-responsive">
                                        <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                                            <thead className="student-thread">
                                                <tr>
                                                    <th>S.No</th>
                                                    {/* <th>Order Id</th> */}
                                                    <th>Amount </th>
                                                    <th>Payment Type</th>
                                                    <th>Transaction Id</th>
                                                    <th>Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {wallethistory && wallethistory.length > 0 ?
                                                    wallethistory.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                {/* <td>{item.order_id}</td> */}
                                                                <td>{item.type === 'add' ? `+${item.amount}` : `-${item.amount}`}</td>
                                                                <td>{item.payment_mode}</td>
                                                                <td>{item.transition_wallet_id}</td>
                                                                <td>{formatDateToCustomString(item.date)}</td>

                                                            </tr>
                                                        );
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan="9" className="text-center">No Data Found!</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StoreWallet;

