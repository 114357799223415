import React, {useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import apple_store from '../../front/frontassest/images/apple-store.png'
import play_store from '../../front/frontassest/images/playstore.png'
import hereoimgae from '../../front/frontassest/images/hero-img.png'
import hereoimgaeInner from '../../front/frontassest/images/inner-hero-imhg.png'
import scrollpng from '../../front/frontassest/images/scroll.png'
import dealbox from '../../front/frontassest/images/deal-box.png'
import cary_ing from '../../front/frontassest/images/carry-ic.svg'
import cary_ing_support from '../../front/frontassest/images/store-ic.svg'
import cary_ing_location from '../../front/frontassest/images/location-ic.svg'
import playstore_svg_check from '../../front/frontassest/images/play-store.svg'
import app_store_svg from '../../front/frontassest/images/app-store.svg'
import deal_svg from '../../front/frontassest/images/deal-side.svg'
import download_icn from '../../front/frontassest/images/download-icn.svg'
import happy_imhg from '../../front/frontassest/images/haappy-icn.svg'
import active_user_icon from '../../front/frontassest/images/activeuser-icn.svg'
import rating_inc from '../../front/frontassest/images/rating-icn.svg'
import featureimg from '../../front/frontassest/images/feature-img.svg'
import AOS from "aos";
import "aos/dist/aos.css";
import ScreenShotSection from './ScreenShotSection';
import { GlobalExportdata } from '../../App';

const Home = () => {

    const { globaldata } = useContext(GlobalExportdata)

    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
            once:true
        });
    }, []);

    return (
        <>
            <div className="hero-section ">
                <div className="container">
                    <div className="row align-items-center overflow-hidden">
                        <div className="col-lg-6" data-aos="slide-right">
                            <div className="text-box">
                                <h1 className="hero-heading" data-aos="zoom-y-out" data-aos-delay="50">
                                    Discover the Excitement of Deal Boxes
                                </h1>
                                <p className="hero-para">
                                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                    typesetting industry
                                </p>
                                <div className="hero-btn-box" data-aos="fade-left" data-aos-delay="500">
                                    <Link className="me-2" to={globaldata?.app_store_link ?? '#'} target='_blank'>
                                        <img
                                            className="img-fluid"
                                            src={apple_store}
                                            alt="btn"
                                        />
                                    </Link>
                                    <Link to={globaldata?.google_play_store ?? '#'} target='_blank'>
                                        <img
                                            className="img-fluid"
                                            src={play_store}
                                            alt="btn"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="slide-left" data-aos-delay="500">
                            <div className="img-box text-end">
                                <img
                                    className="img-fluid hero-side-img"
                                    src={hereoimgae}
                                    alt="img"
                                />
                                <img
                                    className="img-fluid inner-hero-img"
                                    src={hereoimgaeInner}
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="scroll-down" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-delay="500">
                            <div className="scroll d-flex align-items-center gap-2 justify-content-center">
                                <img src={scrollpng} alt="" />
                                <p className="hero-para m-0">Scroll Down</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="deal-box" data-aos="fade-left">
                    <img className="img-fluid" src={dealbox} alt="img" />
                </div>
            </div>
            <section className="uniq-section section ">
                <div className="container">
                    <div className="site-heading-box">
                        <h4 className="sub-heading">lorem ipsum</h4>
                        <h2 className="site-heading">Why are we unique ?</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="500">
                            <div className="single-uniq-box">
                                <div className="icon-box">
                                    <img className="img-fluid" src={cary_ing} alt="icon" />
                                </div>
                                <div className="text-box">
                                    <h3 className="icon-heading mt-3  mt-lg-3">
                                        Seamless Customer <br /> Experience
                                    </h3>
                                    <p className="hero-para m-0 mt-3 mt-lg-3">
                                        Effortless navigation and user-friendly features designed for Link
                                        smooth shopping journey, from registration to purchase and store
                                        management.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="500">
                            <div className="single-uniq-box">
                                <div className="icon-box">
                                    <img className="img-fluid" src={cary_ing_support} alt="icon" />
                                </div>
                                <div className="text-box">
                                    <h3 className="icon-heading mt-3 mt-lg-3">
                                        Simplified Store <br /> Management
                                    </h3>
                                    <p className="hero-para m-0 mt-3 mt-lg-3">
                                        Manage your store effortlessly with intuitive tools for deal box
                                        creation, quantity updates, and performance tracking.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="500">
                            <div className="single-uniq-box">
                                <div className="icon-box">
                                    <img
                                        className="img-fluid"
                                        src={cary_ing_location}
                                        alt="icon"
                                    />
                                </div>
                                <div className="text-box">
                                    <h3 className="icon-heading mt-3 mt-lg-3">
                                        Instant Customer <br /> Support
                                    </h3>
                                    <p className="hero-para m-0 mt-3 mt-lg-3">
                                        Get quick resolutions to your queries through our dedicated Help
                                        and Support section, ensuring customer satisfaction.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section deal-left-section overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-4" data-aos="fade-right" data-aos-delay="500">
                            <div className="text-box">
                                <h1>Lorem Ipsum&nbsp;is simply dummy</h1>
                                <p className="hero-para m-0">
                                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard
                                    dummy text ever
                                </p>
                            </div>
                            <div className="hero-btn-box d-flex mt-3 mt-lg-5">
                              
                                <Link className="me-3" to={globaldata?.google_play_store ?? '#'} target='_blank'>
                                    <img
                                        className="img-fluid"
                                        src={playstore_svg_check}
                                        alt="btn"
                                    />
                                </Link>
                                <Link to={globaldata?.app_store_link ?? '#'} target='_blank'>
                                    <img className="img-fluid" src={app_store_svg} alt="btn" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8" data-aos="fade-left" data-aos-delay="500">
                            <div className="img-box text-end pe-0 pe-lg-5">
                                <img className="img-fluid" src={deal_svg} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section feature-section overflow-hidden">
                <div className="container">
                    <div className="site-heading-box">
                        <h4 className="sub-heading">Features</h4>
                        <h2 className="site-heading">Awosome Features</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4" data-aos="fade-right" data-aos-delay="500">
                            <div className="left-feat-content">
                                <div className="single-feature-box">
                                    <h5 className="feature-heading">Near By Stores</h5>
                                    <p className="hero-para mt-0">
                                        Discover Local Stores based on your GPS location
                                    </p>
                                </div>
                                <div className="single-feature-box">
                                    <h5 className="feature-heading">Discover Deals Instantly</h5>
                                    <p className="hero-para mt-0">
                                        Browse exciting deal boxes right on the homepage. View details
                                        and order with Link click.
                                    </p>
                                </div>
                                <div className="single-feature-box">
                                    <h5 className="feature-heading">Map-Based Deal Finder</h5>
                                    <p className="hero-para mt-0">
                                        See available deal boxes on Link map and shop based on location and
                                        availability.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="500">
                            <div className="img-box text-center">
                                <img className="img-fluid" src={featureimg} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-4" data-aos="fade-left" data-aos-delay="500">
                            <div className="left-feat-content right-feat-content">
                                <div className="single-feature-box">
                                    <h5 className="feature-heading">Effortless Store Management</h5>
                                    <p className="hero-para mt-0">
                                        Create and manage your store, track sales, and update deal boxes
                                        in real-time.
                                    </p>
                                </div>
                                <div className="single-feature-box">
                                    <h5 className="feature-heading">Quick &amp; Easy Registration</h5>
                                    <p className="hero-para mt-0">
                                        Submit queries directly to admin and get fast responses to all
                                        your questions.
                                    </p>
                                </div>
                                <div className="single-feature-box">
                                    <h5 className="feature-heading">24/7 Help &amp; Support</h5>
                                    <p className="hero-para mt-0">
                                        Sign up with email, verify instantly, and log in. Forgot your
                                        password? No worries, recover it in seconds.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section impact-section overflow-hidden">
                <div className="container" data-aos="zoom-in" data-aos-delay="500">
                    <div className="site-heading-box">
                        <h4 className="sub-heading">Lorem Lapisum</h4>
                        <h2 className="site-heading">App Download</h2>
                    </div>
                    <div className="row animate__fadeInUp wow animate__animated animate__delay-2s">
                        <div className="col-lg-3 col-md-6" data-aos="slide-up" data-aos-delay="500">
                            <div className="single-impace-box">
                                <div className="icon-box">
                                    <img
                                        className="img-fluid"
                                        src={download_icn}
                                        alt="icon"
                                    />
                                </div>
                                <div className="text-box">
                                    <h6 className="impace-heading">1000</h6>
                                    <p className="hero-para m-0">app Downloads</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="slide-up" data-aos-delay="500">
                            <div className="single-impace-box">
                                <div className="icon-box">
                                    <img
                                        className="img-fluid"
                                        src={happy_imhg}
                                        alt="icon"
                                    />
                                </div>
                                <div className="text-box">
                                    <h6 className="impace-heading">8k</h6>
                                    <p className="hero-para m-0">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="slide-up" data-aos-delay="500">
                            <div className="single-impace-box">
                                <div className="icon-box">
                                    <img
                                        className="img-fluid"
                                        src={active_user_icon}
                                        alt="icon"
                                    />
                                </div>
                                <div className="text-box">
                                    <h6 className="impace-heading">400</h6>
                                    <p className="hero-para m-0">Active Accounts</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6" data-aos="slide-up" data-aos-delay="500">
                            <div className="single-impace-box">
                                <div className="icon-box">
                                    <img
                                        className="img-fluid"
                                        src={rating_inc}
                                        alt="icon"
                                    />
                                </div>
                                <div className="text-box">
                                    <h6 className="impace-heading">5</h6>
                                    <p className="hero-para m-0">Total App Rates</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='carosel_section'>
               <ScreenShotSection />
            </div>
         

        </>
    )
}

export default Home;
