import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import {
    apibaseURl,
    showErrorToast,
    showSuccessToast,
    tokengenerate,
    getvalidationErr,
    formatDateToCustomString
} from "../../../Function";
import axios from 'axios';
import { AccountContext } from '../../adminContext/AccountProvider';
import { GlobalExportdata } from '../../../App';

const OrderInvoice = () => {
    const { setIstoken } = useContext(AccountContext)
    const [orderinvoicedata, setOrderInvoicedata] = useState({});
    const { id } = useParams();
    const { currency } = useContext(GlobalExportdata)
    // single order api call ===============>

    const fetchSingleorder = async () => {
        try {
            const response = await axios.get(`${apibaseURl}getOrder/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokengenerate()}`
                }
            });
            if (response.status == 200 && response.data.success == true) {
                if (response?.data?.data !== "" && response?.data?.data !== null && response?.data?.data !== undefined) {
                    setOrderInvoicedata(response?.data?.data)
                }
            } else {

            }
        } catch (error) {
            if (error.response && error.response.status == 401 && error.response.data.success === false) {
                showErrorToast(error?.response?.data?.message)
                setIstoken(true)
            } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
                showErrorToast("Server Error.");
            }
        }
    }

    useEffect(() => {
        fetchSingleorder();
    }, [])
    
    return (
        <>
            <div className="content container-fluid">
                <div className="page-header">
                    <section className="order_section_invoice">
                        <div className="section-body">
                            <div className="invoice">
                                <div className="invoice-print">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="invoice-title">
                                                <h2>Invoice</h2>
                                                <div className="invoice-number">Order {orderinvoicedata._id}</div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <address>
                                                        <strong>Billed To:</strong>
                                                        <br />
                                                        {orderinvoicedata?.billing?.firstName},
                                                        <br />
                                                        {orderinvoicedata?.billing?.email},
                                                        <br />
                                                        {orderinvoicedata?.billing?.phone},
                                                        <br />
                                                        {orderinvoicedata?.billing?.address},
                                                        <br />
                                                        {orderinvoicedata?.billing?.city},
                                                        <br />
                                                        {orderinvoicedata?.billing?.country}
                                                    </address>
                                                </div>
                                                <div className="col-md-6 text-md-right">
                                                    <address>
                                                        <strong>Shipped To:</strong>
                                                        <br />
                                                        {orderinvoicedata?.shipping?.firstName},
                                                        <br />
                                                        {orderinvoicedata?.shipping?.email},
                                                        <br />
                                                        {orderinvoicedata?.shipping?.phone},
                                                        <br />
                                                        {orderinvoicedata?.shipping?.address},
                                                        <br />
                                                        {orderinvoicedata?.shipping?.city},
                                                        <br />
                                                        {orderinvoicedata?.shipping?.country}
                                                    </address>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <address>
                                                        <strong>Payment Method:</strong>
                                                        <br />
                                                        {orderinvoicedata?.paymentMethod}
                                                    </address>
                                                    <address>
                                                        <strong>Payment status:</strong>
                                                        <br />
                                                        {orderinvoicedata?.paymentStatus}
                                                    </address>
                                                    <address>
                                                        <strong>Order status:</strong>
                                                        <br />
                                                        {orderinvoicedata?.orderStatus}
                                                    </address>
                                                </div>
                                                <div className="col-md-6 text-md-right">
                                                    <address>
                                                        <strong>Order Date:</strong>
                                                        <br />
                                                        {formatDateToCustomString(orderinvoicedata?.createdAt)}
                                                        <br />
                                                        <br />
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="section-title">Order Summary</div>

                                            <div className="table-responsive">
                                                <table className="table table-striped table-hover table-md">
                                                    <tbody>
                                                        <tr>
                                                            <th data-width={40} style={{ width: 40 }}>
                                                                S.No
                                                            </th>
                                                            <th>Item</th>
                                                            <th className="text-center">Price</th>
                                                            <th className="text-center">Quantity</th>
                                                            <th className="text-right">Totals</th>
                                                        </tr>
                                                        {orderinvoicedata && orderinvoicedata.orderItems && orderinvoicedata.orderItems.length > 0 ?
                                                            (orderinvoicedata.orderItems.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.productName}</td>
                                                                        <td className="text-center">{currency}{item.price}</td>
                                                                        <td className="text-center">{item.quantity}</td>
                                                                        <td className="text-right">{currency}{item.price * item.quantity}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                            )
                                                            : (
                                                                <tr>
                                                                    <td colSpan={9}>No Data Found.</td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-8">
                                                </div>
                                                <div className="col-lg-4 text-right">
                                                    <div className="invoice-detail-item">
                                                        <div className="invoice-detail-name">Subtotal</div>
                                                        <div className="invoice-detail-value">{currency} {orderinvoicedata?.totalAmount}</div>
                                                    </div>
                                                    <div className="invoice-detail-item">
                                                        <div className="invoice-detail-name">Shipping</div>
                                                        <div className="invoice-detail-value">{currency} 0</div>
                                                    </div>
                                                    <hr className="mt-2 mb-2" />
                                                    <div className="invoice-detail-item">
                                                        <div className="invoice-detail-name">Total</div>
                                                        <div className="invoice-detail-value invoice-detail-value-lg">
                                                           {currency} {orderinvoicedata?.totalAmount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="text-md-right">

                                    <button className="btn btn-warning btn-icon icon-left">
                                        <i className="fas fa-print" /> Print
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default OrderInvoice;