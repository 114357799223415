import React, { useContext } from "react";
import { GlobalExportdata } from "../../../App";

const Footer = () => {
  const {globaldata}  = useContext(GlobalExportdata)
  
  return (
    <>
      <div class="page-wrapper">
        <footer>
          <p>{globaldata?.footerText}</p>
        </footer>
      </div>
    </>
  );
};

export default Footer;
