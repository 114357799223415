import React, { lazy, useContext, useState } from "react";
// import AdminCss from "../component/layout/AdminCss";
// import loginimg from "../assets/img/1-min.jpg";
import axios from "axios";
import {
  apibaseURl,
  showSuccessToast,
  getvalidationErr,
  showErrorToast,
} from "../../Function.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AccountContext } from "../adminContext/AccountProvider.js";
const AdminCss = lazy(() => import("../component/layout/AdminCss"));
const Login = () => {

  const {setIsLogin,setIstoken} = useContext(AccountContext)
  const[loading,setIsLoading] = useState(false)
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    setIsLogin(false);
    setIsLoading(true)
    event.preventDefault();
    const registerData = {
      email: inputs.email,
      password: inputs.password,
    };

    try {
      const responce = await axios.post(`${apibaseURl}adminuserLogin`, registerData );
      if (responce.status == 200 && responce.data.success == true) {
        showSuccessToast(responce?.data?.message);
        const tokendata = {
          token: responce?.data?.data?.token,
          user: responce?.data?.data?.user,
        };
        localStorage.setItem("winwinUser", JSON.stringify(tokendata));
        localStorage.setItem("Authlogout", 0);
        navigate("/admin/dashboard");
        setInputs("");
        setIsLogin(true);
        setIstoken(false)
        setIsLoading(false)
      } else {
        if (responce?.data?.errors  && responce?.data?.errors.length>0 ) {
          const errorMessages = getvalidationErr(responce?.data?.errors);
          showErrorToast(errorMessages);
        } else {
          showErrorToast(responce?.data?.message);
        }
      }
    } catch (error) {

    }finally{
      setIsLoading(false)
    }
  };

  return (
    <>
      <AdminCss />
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-left">
                {/* <img className="img-fluid" src={loginimg} alt="Logo" /> */}
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Welcome to WinWin</h1>

                  <h2>Sign in</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>
                        Email <span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                      />
                      <span className="profile-views">
                        <i className="fas fa-user-circle" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label>
                        Password <span className="login-danger">*</span>
                      </label>
                      <input 
                      className="form-control pass-input" 
                      type="password"
                      name="password"
                      value={inputs.password}
                      onChange={handleChange}
                         />
                      <span className="profile-views feather-eye toggle-password" />
                    </div>

                    <div className="form-group">
                      <button className="btn btn_primary_login btn-block" type="submit">{loading ? "...Sending":"Login"}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
