import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { apibaseURl, showErrorToast, showSuccessToast, tokengenerate, getvalidationErr, fileUpload } from '../../../Function'
import { GlobalExportdata } from '../../../App';
import { AccountContext } from '../../adminContext/AccountProvider';

const Setting = () => {

  const { setIstoken } = useContext(AccountContext);
  const { setglobalSettingupdate } = useContext(GlobalExportdata)
  const [input, setInput] = useState({})
  const SiteLogo = useRef()
  const SiteFavicon = useRef()
  //  file upload api call
  const handleFileUploadimage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(`${apibaseURl}upload`, formData,
        {
          headers: {
            Authorization: `Bearer ${tokengenerate()}`,
          },
        }
      );

      if (response.status === 200 && response.data.success === true) {
        if (e.target.name === "SiteLogo") {
          setInput((prevData) => ({
            ...prevData,
            'SiteLogo': response.data.data.image,
          }));
        } else if (e.target.name === "SiteFavicon") {
          setInput((prevData) => ({
            ...prevData,
            'SiteFavicon': response?.data?.data?.image,
          }));
        }
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response.data.msg);
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {

    }
  };
  // setting view api call
  const fetchsettingsApi = async () => {
    try {
      const response = await axios.get(`${apibaseURl}Settings`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status == 200 && response.data.success === true) {
        console.log('response', response)
        setInput(response?.data?.data)
      } else {

      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  useEffect(() => {
    fetchsettingsApi();
  }, [])

  // settings update api call ===================>
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input is a checkbox, update based on checked state

    if (name === "StoreCommision" && !/^\d*\.?\d*$/.test(value)) {
      return;
    }
    if (name === "Sitetitle"
      || name === "ConatctEmail"
      || name === "ContactNumber"
      || name === "ContactAddress"
      || name === "StoreCommision"
      || name === "currency"
      || name === "currency_symbal"
      || name === "google_map_key"
      || name === "paymnent_publish_key"
      || name === "paymnent_secret_key"
      || name === "from_name"
      || name === "from_email"
      || name === "facebookicon"
      || name === "youtubicon"
      || name === "whatsupicon"
      || name === "linkedinicon"
      || name === "footerText"
      || name === "footer_abouts_text"
      || name === "app_store_link"
      || name === "google_play_store"
      || name === "admin_email"
      || name === "twillo_from_number"
      || name === "twillo_accountsid"
      || name === "twillo_authtoken"
      || name === "smtp_host"
      || name === "smtp_port"
      || name === "smtp_username"
      || name === "smtp_password"
      || name === "mail_encryption"
      || name === "twilio_check"
    ) {
      setInput((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setglobalSettingupdate(false)
    try {
      const response = await axios.put(`${apibaseURl}updateSettings`, input, {
        headers: {
          'Authorization': `Bearer ${tokengenerate()}`
        }
      })

      if (response.status === 200) {
        if (response.status == 200 && response.data.success == true) {
          showSuccessToast(response.data.message);
          setglobalSettingupdate(true)
          fetchsettingsApi()
        } else {
          const errorMessages = getvalidationErr(response?.data?.errors);
          showErrorToast(errorMessages);
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  // const handledeleteimage = (data) => {
  //   if (data == "SiteLogo") {
  //     SiteLogo.current.value = "";
  //     setInput((prevData) => ({
  //       ...prevData,
  //       'SiteLogo': "",
  //     }));
  //   } else {
  //     SiteFavicon.current.value = "";
  //     setInput((prevData) => ({
  //       ...prevData,
  //       'SiteFavicon': "",
  //     }));
  //   }
  // }

  // sitelogo delete image  api
  const handledeleteimage = async (id, data) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const responce = await handledelete(id);

        if (responce.status === 200 && responce.data.success === true) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          if (data == 'SiteLogo') {
            if (SiteLogo.current) {
              SiteLogo.current.value = "";
              setInput((prevData) => ({
                ...prevData,
                'SiteLogo': "",
              }));
            }
          } else {
            if (SiteFavicon.current) {
              SiteFavicon.current.value = "";
              setInput((prevData) => ({
                ...prevData,
                'SiteFavicon': "",
              }));
            }
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Sorry, you can't delete it",
            text: responce.message,
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message);
        setIstoken(true);
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };

  // API Call for deletion
  const handledelete = async (id) => {
    const responce = await axios.get(`${apibaseURl}delete-image/${id}`, {
      headers: {
        Authorization: `Bearer ${tokengenerate()}`,
      },
    });
    return responce;
  };


  // sitelogo delete image  api


  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Settings</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ul>
            </div>
          </div>
        </div>


        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Site basic details</h5>
                </div>
                <div className="card-body pt-0">

                  <div className="settings-form">
                    <div className="form-group">
                      <label>
                        Site name <span className="star-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="Sitetitle"
                        value={input.Sitetitle}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">

                      {input.SiteLogo ?
                        <div className="upload-images-bar">
                          <img src={fileUpload + input.SiteLogo} alt="Img" />
                          <Link to="#" className="btn-icon logo-hide-btn"
                            onClick={() => handledeleteimage(input.SiteLogo, 'SiteLogo')}>
                            <i className="feather-x-circle" />
                          </Link>
                        </div>
                        : ""}

                      <p className="settings-label">
                        Logo <span className="star-red"></span>
                      </p>
                      <div className="settings-btn">
                        <input
                          type="file"
                          accept="image/*"
                          id="file1"
                          onchange="loadFile(event)"
                          className="hide-input"
                          name="SiteLogo"
                          ref={SiteLogo}
                          onChange={handleFileUploadimage}
                        />
                        <label htmlFor="file" className="upload">
                          <i className="feather-upload" />
                        </label>
                      </div>


                    </div>
                    <div className="form-group">
                      {input.SiteFavicon ?
                        <div className="upload-images-bar">
                          <img src={fileUpload + input.SiteFavicon} alt="Img" />
                          <Link to="#" className="btn-icon logo-hide-btn"
                            onClick={() => handledeleteimage(input.SiteFavicon, 'SiteFavicon')}>
                            <i className="feather-x-circle" />
                          </Link>
                        </div>
                        : ""}
                      <p className="settings-label">
                        Favicon <span className="star-red"></span>
                      </p>


                      <div className="settings-btn">
                        <input
                          type="file"
                          accept="image/*"
                          id="file2"
                          onchange="loadFile(event)"
                          className="hide-input"
                          name="SiteFavicon"
                          ref={SiteFavicon}
                          onChange={handleFileUploadimage}
                        />
                        <label htmlFor="file" className="upload">
                          <i className="feather-upload" />
                        </label>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        Footer text <span className="star-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="footerText"
                        value={input.footerText}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Footer about us <span className="star-red"></span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Footer About Us"
                        name="footer_abouts_text"
                        value={input.footer_abouts_text}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-6 col-md-8">
              <div className='row'>
                <div className='col-md-12'>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Site global details</h5>
                    </div>
                    <div className="card-body pt-0">
                      <div className="settings-form">

                        <div className="form-group">
                          <label>
                            Currency <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="currency"
                            value={input.currency}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Currency  symbal <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="currency_symbal"
                            value={input.currency_symbal}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Store commision (%)<span className="star-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="StoreCommision"
                            value={input.StoreCommision}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* // store linsk details  ======================================= */}
                <div className='col-md-12'>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Store links details</h5>
                    </div>
                    <div className="card-body pt-0">
                      <div className="settings-form">

                        <div className="form-group">
                          <label>
                            App store <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="app_store_link"
                            value={input.app_store_link}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Google play store <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="google_play_store"
                            value={input.google_play_store}
                            onChange={handleInputChange}
                          />
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                {/* // store linsk details  ======================================= */}
                {/* // google map linsk details  ======================================= */}
                <div className='col-md-12'>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Google map key details</h5>
                    </div>
                    <div className="card-body pt-0">
                      <div className="settings-form">


                        <div className="form-group">
                          <label>
                            Google map key <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="google_map_key"
                            value={input.google_map_key}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* // google map linsk details  ======================================= */}

              </div>

            </div>


            <div className="col-lg-12 col-md-8">
              <div className='row'>
                <div className='col-md-6'>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Resend mail details</h5>
                    </div>
                    <div className="card-body pt-0">

                      <div className="settings-form">
                        <div className="form-group">
                          <label>
                            From email name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="from_name"
                            value={input.from_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            From email <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="from_email"
                            value={input.from_email}
                            onChange={handleInputChange}
                          />
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
                <div className='col-md-6'>

                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Payment getway details</h5>
                    </div>
                    <div className="card-body pt-0">

                      <div className="settings-form">
                        <div className="form-group">
                          <label>
                            Payment getway publishable key <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="paymnent_publish_key"
                            value={input.paymnent_publish_key}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Payment getway secret key <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="paymnent_secret_key"
                            value={input.paymnent_secret_key}
                            onChange={handleInputChange}
                          />
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-12 col-md-8">
              <div className='row'>
                <div className='col-md-12'>
                  <div className="card">
                    <div className="card-header">
                  
                      <div className="status-toggle d-flex justify-content-between align-items-center">
                        <p className="mb-0">Twilio details</p>
             
                        <input
                          type="checkbox"
                          id="status_1"
                          className="check"
                          // Set the checked property based on the boolean value of input.twillo_active_key
                          checked={input.twilio_check == 1}
                          name="twilio_check"
                          value={input.twilio_check == 1 ? 0 : 1}
                          onChange={(e) => {
                            const isChecked = e.target.value;
                            setInput((prevData) => ({
                              ...prevData,
                              [e.target.name]: isChecked , // Set to 1 if checked, 0 if unchecked
                            }));
                          }}
                        />


                        <label htmlFor="status_1" className="checktoggle">
                          checkbox
                        </label>
                      </div>
                    </div>



                    <div className="card-body pt-0">

                      <div className="settings-form">
                        <div className="form-group">
                          <label>
                            Twilio from number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="twillo_from_number"
                            value={input.twillo_from_number}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Twilio accountsid<span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="twillo_accountsid"
                            value={input.twillo_accountsid}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Twilio authtoken<span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="twillo_authtoken"
                            value={input.twillo_authtoken}
                            onChange={handleInputChange}
                          />
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-12 col-md-8">
              <div className='row'>
                <div className='col-md-12'>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Smtp details</h5>
                    </div>
                    <div className="card-body pt-0">

                      <div className="settings-form">
                        <div className="form-group">
                          <label>
                            Smtp host
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="smtp_host"
                            value={input.smtp_host}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Smtp port<span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="smtp_port"
                            value={input.smtp_port}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Smtp username<span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="smtp_username"
                            value={input.smtp_username}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Smtp password<span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="smtp_password"
                            value={input.smtp_password}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            Mail encryption<span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="mail_encryption"
                            value={input.mail_encryption}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-12 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Contact details</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="settings-form">
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <label>
                            Admin contact email <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="admin_email"
                            value={input.admin_email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <label>
                            Admin contact number <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="admin_contact"
                            value={input.admin_contact}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <label>
                            Contact support email <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ConatctEmail"
                            value={input.ConatctEmail}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <label>
                            Contact support number <span className="star-red"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ContactNumber"
                            value={input.ContactNumber}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Contact address <span className="star-red"></span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Contact Address"
                        name="ContactAddress"
                        value={input.ContactAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Social links</h5>
                </div>
                <div className="card-body pt-0">

                  <div className="settings-form">
                    <div className="links-info">
                      <div className="row form-row links-cont">
                        <div className="form-group form-placeholder d-flex">
                          <Link className="btn social-icon" type='btn'>
                            <i className="feather-facebook" />
                          </Link>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="facebookicon"
                            value={input.facebookicon}
                            onChange={handleInputChange}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="links-info">
                      <div className="row form-row links-cont">
                        <div className="form-group form-placeholder d-flex">
                          <Link className="btn social-icon" type='btn'>
                            <i className="feather-instagram" />
                          </Link>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="Instagramicon"
                            value={input.Instagramicon}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="links-info">
                      <div className="row form-row links-cont">
                        <div className="form-group form-placeholder d-flex">
                          <Link className="btn social-icon" type='btn'>
                            <i className="feather-youtube" />
                          </Link>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="youtubicon"
                            value={input.youtubicon}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="links-info">
                      <div className="row form-row links-cont">
                        <div className="form-group form-placeholder d-flex">
                          <Link className="btn social-icon" type='btn'>
                            <i className="feather-linkedin" />
                          </Link>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="linkedinicon"
                            value={input.linkedinicon}
                            onChange={handleInputChange}
                          />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-0">
                    <div className="settings-btns">
                      <button type="submit" className="btn btn-orange">
                        Submit
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>

      </div>

    </>
  )
}

export default Setting;
