import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {getDatabase} from 'firebase/database'
import { apibaseURl, showSuccessToast, tokengenerate } from '../Function';
import notificationSound from '../admin/assets/img/notification.wav';

import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyBo2o9zIZHKRzfxlLgkZewqUT4K9NLle7o",
    authDomain: "winwin-5723d.firebaseapp.com",
    databaseURL: "https://winwin-5723d-default-rtdb.firebaseio.com/",
    projectId: "winwin-5723d",
    storageBucket: "winwin-5723d.appspot.com",
    messagingSenderId: "699056243375",
    appId: "1:699056243375:web:f835469505baa6f3010a5a",
    measurementId: "G-2N1TF2LWQ7"
  };
  
const validKey = 'BINvBkNgKlTdocYadeT58dS7D8L4fAQl_Cq_PpBpvNssYoly9zkDi0KL98oDrKQQuSn6O0qW9A2jtaTJgIc678U';

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const db = getDatabase(app)

// Function to play the notification sound
const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
};

export const requestFromToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const currentToken = await getToken(messaging, { vapidKey: validKey });
            if (currentToken) {
                await updateFcmToken(currentToken);
                onMessage(messaging, (payload) => {
                    playNotificationSound();
                    showSuccessToast( 
                      <div>
                        <strong>{payload?.notification?.title}</strong><br/>
                        <strong style={{fontSize:"14px"}}>{payload?.notification?.body}</strong>
                      </div>
                      )
                });
     
            } else {
                
            }
        } else {
           
        }
    } catch (err) {
     
    }
};


const updateFcmToken = async (fcmtoken) => {
    try {
        const response = await axios.post(`${apibaseURl}update-fcm-token`, {
            fcmtoken: fcmtoken
        }, {
            headers: {
                Authorization: `Bearer ${tokengenerate()}`
            }
        });
        // console.log('Token updated successfully:', response.data);
    } catch (error) {
        // console.error('Error updating FCM token:', error);
    }
};
