import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png'
import user from '../../assets/img/user.png'
import { fileUpload, getTimeAgo } from "../../../Function";
import { AccountContext } from "../../adminContext/AccountProvider";
import { GlobalExportdata } from "../../../App";
import { ref, onValue, update, remove } from 'firebase/database';
import { db } from '../../../utils/FirebaseUtils';
import { FiBell } from "react-icons/fi";
import { AiOutlineGlobal } from "react-icons/ai";
import { TbBuildingStore } from "react-icons/tb";
import GTranslate from "../../../GTranslate ";
import { FaBox } from "react-icons/fa";

const Header = ({ handleToggleClick, handleSlidenaveChange }) => {
  const { logoutadmin, setIsLogin, userData, isLogin } = useContext(AccountContext)
  const { globaldata } = useContext(GlobalExportdata)
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setnotificationsCount] = useState("");

  useEffect(() => {
    const dataRef = ref(db, 'users');
    const unsubscribe = onValue(dataRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data)
        const notificationArray = Object.entries(data).map(([id, notification]) => ({ id, ...notification }));
        let webNotifications = notificationArray.filter(notification => notification.platform === "web");
        let webNotificationsunread = webNotifications.filter(notification => notification.read === false);
        webNotifications.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Filter out recent notifications within 2 days
        const filterRecentNotifications = (notifications) => {
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
          return notifications.filter(notification => {
            const notificationDate = new Date(notification.date);
            return notificationDate >= twoDaysAgo;
          });
        };

        const recentNotifications = filterRecentNotifications(webNotifications);
        const unreadtwodays = recentNotifications.filter(notification => notification.read === false);
        // Set notifications and count
        setNotifications(recentNotifications);
        // Display sorted notifications
        setnotificationsCount(unreadtwodays.length);
      }
    });

    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   const dataRef = ref(db, 'users');
  //   const deleteUsers = async () => {
  //     try {
  //       await remove(dataRef);
  //       console.log("All users have been deleted successfully.");
  //     } catch (error) {
  //       console.error("Error deleting users:", error);
  //     }
  //   };
  //   deleteUsers();
  // }, []);

  const handleNotificationClick = (notifId) => {
    const notificationRef = ref(db, `users/${notifId}`);
    update(notificationRef, {
      read: true
    })
      .then(() => {
        setNotifications(prevNotifications =>
          prevNotifications.map(notif =>
            notif.related_id === notifId ? { ...notif, read: true } : notif
          )
        );
      })
      .catch((error) => {
      });
  };

  let Authlogout = localStorage.getItem('Authlogout');


  return (
    <>

      <div className="header">
        <div className="header-left">
          <Link to="#" className="logo">
            <img src={globaldata ? fileUpload + globaldata?.SiteLogo : logo} alt="Logo" />

          </Link>
          <Link to="#" className="logo logo-small">
            <img
              src={globaldata ? fileUpload + globaldata?.SiteLogo : logo}
              alt="Logo"
              width={30}
              height={30}
            />
          </Link>
        </div>
        <div className="menu-toggle">
          <Link id="toggle_btn" onClick={handleToggleClick}>
            <i className="fas fa-bars" />
          </Link>
        </div>
        <Link className="mobile_btn" id="mobile_btn" onClick={handleSlidenaveChange}>
          <i className="fas fa-bars" />
        </Link>


        <ul className="nav user-menu">

          {Authlogout == 0 && (
            <li>
              <GTranslate Authlogout={Authlogout} />
            </li>
          )
          }

          <li className="nav-item dropdown noti-dropdown me-2">
            <div className="go_to_home_to_bar">

              <Link to="/" target="_blank">
                <AiOutlineGlobal className="earth_global_website" />
              </Link>

            </div>
          </li>
          <li>

          </li>
          <li className="nav-item dropdown noti-dropdown me-2">
            <Link
              to="#"
              className="dropdown-toggle nav-link header-nav-list"
              data-bs-toggle="dropdown"
            >
              <div className="bell_icon_chek_permission">
                {notificationsCount > 0 && (
                  <span className="bell_header_icon_count">{notificationsCount}</span>
                )}

                <FiBell className="bell_header_icon" />
              </div>

            </Link>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>

              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <ul className="notification-list">
                    {notifications.map((notif) => (
                      <li className={`notification-message ${notif.read == false ? "notification_message_count" : ""}`} key={notif.id} onClick={() => handleNotificationClick(notif.id)}>
                        <Link
                          to={
                            notif.related_type === "order" ? "/admin/orders" :
                              notif.related_type === "store" ? "/admin/store" :
                                notif.related_type === "dealbox" ? "/admin/deal-box" :
                                  notif.related_type === "user" ? "/admin/users" :
                                    "#"}
                        >


                          <div className="media d-flex mode_for_notification">
                            {notif.related_type == "order" ?
                              <span className="icon_decode_check">
                                <i className="fa fa-cart-arrow-down" />
                              </span>
                              : notif.related_type == "store" ?
                                <span className="icon_decode_check_store">
                                  <TbBuildingStore className="store_icon_represent" />
                                </span>
                                : notif.related_type == "dealbox" ?
                                  <span className="icon_decode_check_store_deal">
                                    <FaBox className="store_icon_represent" />
                                  </span>
                                  : notif.related_type == "user" ?
                                    <span className="icon_decode_check_store_deal_user">
                                      <i className="fa fa-user store_icon_represent" />
                                    </span>
                                    : ""
                            }

                            <div className="media-body flex-grow-1">
                              <p className="noti-time">
                                <span className="noti-title">{notif.title}</span><br></br>
                                <span className="notification-time">{notif.message}</span><br></br>
                                <span className="msg-time float-end">{getTimeAgo(notif.date)}</span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>

                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                {/* <Link to="#">View all Notifications</Link> */}
              </div>
            </div>
          </li>




          <li className="nav-item dropdown has-arrow new-user-menus">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                {userData && userData.image ?
                  <img
                    className="rounded-circle"
                    src={fileUpload + userData.image}
                    width={31}
                    alt="Soeng Souy"
                  />
                  :
                  <img
                    className="rounded-circle"
                    src={user}
                    width={31}
                    alt="Soeng Souy"
                  />
                }

                <div className="user-text">
                  <h6>{userData ? userData.name : ""}</h6>
                  <p className="text-muted mb-0">Administrator</p>
                </div>
              </span>
            </Link>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  {userData && userData.image ?
                    <img
                      className="rounded-circle"
                      src={fileUpload + userData.image}
                      width={31}
                      alt="Soeng Souy"
                    />
                    :
                    <img
                      className="rounded-circle"
                      src={user}
                      width={31}
                      alt="Soeng Souy"
                    />
                  }
                </div>
                <div className="user-text">
                  <h6>{userData ? userData.name : ""}</h6>
                  <p className="text-muted mb-0">Administrator</p>
                </div>
              </div>
              <Link className="dropdown-item" to="/admin/profile">
                My Profile
              </Link>

              <Link className="dropdown-item" onClick={() => {
                logoutadmin.logout();
                setIsLogin(false);
                localStorage.setItem("Authlogout", 1);
                window.location.reload();
              }}>
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </div>

    </>
  );
};

export default Header;
