import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apibaseURl, tokengenerate, showSuccessToast, showErrorToast, getvalidationErr } from '../../../Function'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AccountContext } from '../../adminContext/AccountProvider';


const EditEmailTemplate = () => {
  const { setIstoken } = useContext(AccountContext)
  const [inputs, setInputs] = useState({
    template: "",
    template_key: "",
    subject: "",
    status: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();
  //single email template =============================>
  const fetchSingleEmailTemplate = async () => {
    try {
      const response = await axios.get(`${apibaseURl}singleMailTemplatedata/${id}`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });
      if (response.status == 200 && response.data.success === true) {
        setInputs(response?.data?.data)
      } else {

      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  useEffect(() => {
    fetchSingleEmailTemplate();
  }, [])
  // category listing show api call ===================>
  const handleChangeget = (event, editor) => {
    setInputs((pre) => ({
      ...pre,
      template: editor.getData()
    }))
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const addmailtemplate = {
      template_key: inputs.template_key,
      template: inputs.template,
      subject: inputs.subject,
      status: inputs.status,

    }
 
    try {
      const responce = await axios.put(`${apibaseURl}mailTemplateUpdate/${id}`, addmailtemplate, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      })
 
      if (responce.status == 200 && responce.data.success == true) {
        showSuccessToast(responce?.data?.message);
        navigate('/admin/email-temaplate')
        setInputs("")
      } else {
        const errorMessages = getvalidationErr(responce?.data?.errors);
        showErrorToast(errorMessages);
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }



  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Edit Email Template</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/email-temaplate">Email Template</Link>
                </li>
                <li className="breadcrumb-item active">Edit Email Template</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h3 className="page-title">Edit Email Template</h3>
                          </div>
                          <div className="col-auto text-end float-end ms-auto download-grp">
                            <Link to="/admin/email-temaplate" className="btn btn-primary">
                              <i className="fa fa-angle-double-left" /> Back
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>
                          Subject
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          name='subject'
                          value={inputs.subject}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>
                          Template Key
                          <span className="login-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Template key"
                          name='template_key'
                          value={inputs.template_key}
                          readOnly
                           onChange={handleChange}
                        />
                      </div>
                    </div>


                    <div className="col-12">
                      <div className='variable_decleare_define'>
                        {inputs?.varibles ? inputs.varibles :""}
                      </div>
                    </div>


                    <div className="col-12">
                      <div className="form-group local-forms">
                        <label>
                          Template <span className="login-danger"></span>
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={inputs.template ?? ''}
                          onChange={handleChangeget}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12">
                      <div class="form-group">
                        <label >Status <span class="star-red"></span></label>
                        <select class="select form-control" value={inputs.status} name='status' onChange={handleChange}>
                          <option selected="selected">Select</option>
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="student-submit">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditEmailTemplate;

