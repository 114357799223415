import React, { useContext } from 'react'
import logo from '../../../front/frontassest/images/logo.png';
import { Link } from 'react-router-dom';
import { fileUpload } from '../../../Function';
import { GlobalExportdata } from '../../../App';


const Header = () => {
    
    const { globaldata } = useContext(GlobalExportdata)
    
    return (
        <header>
            <div className="overflow-hidden">
                <div className="page-header">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg justify-content-between">
                            <Link className="navbar-brand p-0" to="/">
                               <img
                                    src={globaldata?.SiteLogo ? fileUpload + globaldata.SiteLogo : logo}
                                    alt="Logo"
                                />
                            </Link>
                            <div className="header-button-box">
                                <span className="header-relative header-relative-2 me-1 me-lg-3 me-md-3">
                                    <Link className="btn site-btn site-btn-v-2" to="">
                                        Download the App
                                    </Link>
                                </span>
                                <span className="header-relative">
                                    <Link className="btn site-btn" to="">
                                        Contact us
                                    </Link>
                                </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
