import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dummyUserImg from '../../assets/img/download.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AccountContext } from '../../adminContext/AccountProvider';
import { apibaseURl, tokengenerate, formatDateToCustomString, showErrorToast, loaderFunction, fileUpload } from '../../../Function';
import { GlobalExportdata } from '../../../App';

const OrderList = () => {
  const [show, setShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [storeName, setStoreName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orderDetail, setOrderDetail] = useState({});
  const [orderDetailisLoading, setOrderDetailisLoading] = useState(true);
  const { setIstoken } = useContext(AccountContext);
  const { currency } = useContext(GlobalExportdata);
  const [commonstoreList, setCommonstoreList] = useState([]);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize,setPageSize] = useState(100); // Number of items per page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Fetch orders with filters and pagination
  const fetchAllOrders = async (storeName, startDate, endDate, page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apibaseURl}orders`, {
        params: {
          store_name: storeName,
          start_date: startDate,
          end_date: endDate,
          page, // Send current page
          pageSize // Send page size
        },
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status === 200 && response.data.success === true) {
        setOrders(response.data.data || []);
        setTotalPages(response.data.totalPages || 1);
      } else {
        showErrorToast(response.data.message || "An error occurred.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle form submission
  useEffect(() => {
    fetchAllOrders(storeName, startDate, endDate, currentPage);
  }, [currentPage,pageSize]); // Fetch orders whenever the page changes

  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page on search
    fetchAllOrders(storeName, startDate, endDate, 1);
  };

  const handleSearchChange = (event) => {
    const name = event.target.name;
    if (name === "storeName") {
      setStoreName(event.target.value);
    } else if (name === "startDate") {
      setStartDate(event.target.value);
    } else {
      setEndDate(event.target.value);
    }
  };

  const handleClear = () => {
    setStoreName("");
    setStartDate("");
    setEndDate("");
    setCurrentPage(1); // Reset to first page on clear
    fetchAllOrders("", "", "", 1); // Fetch all orders
  };

  // Handle single Order API Call
  const fetchSingleStore = async (itemid) => {
    try {
      const response = await axios.get(`${apibaseURl}orders/${itemid}`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status === 200 && response.data.success === true) {
        setOrderDetail(response.data.data);
        setOrderDetailisLoading(false);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setOrderDetailisLoading(false);
    }
  };

  const handleGetOrder = (item_id) => {
    fetchSingleStore(item_id);
    handleShow();
  };

  const handleError = (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 401 && data.success === false) {
        showErrorToast(data.message || "Unauthorized.");
        setIstoken(true);
      } else if (status === 500 && data.success === false) {
        showErrorToast(data.message || "Server Error.");
      } else {
        showErrorToast(data.message || "An unexpected error occurred.");
      }
    } else {
      showErrorToast("Network error or server is unreachable.");
    }
  };

  // common store lists ================>

  const fetchstorecommonlist = async () => {
    try {
      const response = await axios.get(`${apibaseURl}store-common-list`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });


      if (response.status === 200 && response.data.success === true) {
        setCommonstoreList(response?.data?.data)
        setIsLoading(false)
      } else {
        setCommonstoreList([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchstorecommonlist();
  }, [])

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Order Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">All Orders</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center gy-4 responsive_bar_check">
                    <div className="col">
                      <h3 className="page-title">Orders List</h3>
                    </div>

                    <div className="col">
                      <select class="select form_input form-control" name='storeName' value={storeName} onChange={handleSearchChange}  >
                        <option selected="selected">Select Store</option>
                        {commonstoreList.map(store => (
                          <option key={store.id} value={store.store_name}>
                            {store.store_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col">
                      <input
                        type="date"
                        className="form_input form-control"
                        placeholder="Start Date"
                        name="startDate"
                        value={startDate}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <div className="col">
                      <input
                        type="date"
                        className="form_input form-control"
                        placeholder="End Date"
                        name="endDate"
                        value={endDate}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div className='col text_end_check'>
                      <button type='button' onClick={handleSearch}
                        className="btn btn-sm btn-danger store_add_wallet_add">
                        <i className="feather-search" />&nbsp;
                        Search
                      </button> &nbsp;
                      <button type='button' onClick={handleClear}
                        className="btn btn-sm btn-danger store_add_wallet_add_red">
                        <i className="fa fa-times" aria-hidden="true"></i>&nbsp;
                        Clear
                      </button>
                    </div>

                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label className='label_for_entryes'>
                          Show{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            className="custom-select custom-select-sm form-control form-control-sm"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
                {isLoading ? (
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Id</th>
                          <th>Store</th>
                          <th>Customer</th>
                          <th>Order<br /> Amount</th>
                          {/* <th>Commission<br /> (%)</th> */}
                          <th>Admin <br /> Commission</th>
                          <th>Net Order<br /> Amount</th>
                          <th>Order <br /> Date</th>
                          <th>Payment <br /> Status</th>
                          <th>Order <br /> Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.length > 0 ? (
                          orders.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * pageSize + index + 1}</td>
                              <td>{item.id}</td>
                              <td>
                                Id : {item.store_id}<br />
                                {/* {item?.store_details?.store_name}<br /> */}
                              </td>
                              <td>
                                Id : {item.customer_id}<br />
                                {/* {item?.customer_details?.name} */}
                              </td>
                              <td>{item.currency} {item.order_total}</td>
                              {/* <td>{item?.commission_percentage} %</td> */}
                              <td>{item.currency} {item?.commission_amount}</td>
                              <td>{item.currency} {item.order_total - item?.commission_amount}</td>
                              <td>{formatDateToCustomString(item.order_date)}</td>
                              <td>
                                <span className="badge badge-success text_transform_uppercase">{item.payment_status}</span>
                              </td>
                              <td>
                                <span className="badge badge-success text_transform_uppercase">{item.order_status}</span>
                              </td>
                              <td className="text-end">
                                <Link to="" onClick={() => handleGetOrder(item.id)} className="btn btn-sm btn-danger store_add_wallet_add">
                                  <i className="feather-eye" />&nbsp;
                                  View
                                </Link> &nbsp;
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={11}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='for_data_structure_for_pagination'>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</a>
                  </li>
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, index) => {
                    const pageIndex = index + 1;
                    return (
                      <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                        <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                      </li>
                    );
                  })}
                  {totalPages > 5 && (
                    <li className="page-item">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  {totalPages > 5 && (
                    <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                      <a className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</a>
                    </li>
                  )}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Invoice &nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className='order_invoice_list_check'>
                <div>
                  <img src={orderDetail?.store_details?.store_logo ? fileUpload + orderDetail?.store_details?.store_logo : dummyUserImg} alt='store' className='store_image_check_bar' />
                </div>

                <div>
                  Order Id : # {orderDetail.order_id}<br />
                  {orderDetail?.store_details?.store_name}<br />
                  {orderDetail?.store_details?.store_address}<br />
                  Order Date : {formatDateToCustomString(orderDetail?.order_date)}
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5">
              <h5>Order Summary</h5>
              <div className='order_summary_div mt-2'>
                <div>
                  Order Status
                </div>
                <div>
                  <span className="badge badge-success bdge_app_css">{orderDetail?.order_status}</span>
                </div>
              </div>
              <div className='order_summary_div mt-2'>
                <div>
                  Payment Status
                </div>
                <div>
                  <span className="badge badge-success bdge_app_css">{orderDetail?.payment_status}</span>
                </div>
              </div>
              <div className='item_summary_div mt-3'>
                <div>
                  {orderDetail?.order_item_name}<br />
                  {orderDetail?.qty} X {currency} {orderDetail?.price}
                </div>
                <div>
                  {currency} {orderDetail?.order_total}
                </div>
              </div>

              <div className='item_summary_sub_div mt-3'>
                <div>
                  <h6>Commission  (%)</h6>
                </div>
                <div>
                  %  {orderDetail?.commission_percentage}
                </div>
              </div>

              <div className='item_summary_sub_div mt-3'>
                <div>
                  <h6>Commission Amount</h6>
                </div>
                <div>
                  {currency} {orderDetail?.commission_amount}
                </div>
              </div>

              <div className='item_summary_sub_div mt-3'>
                <div>
                  <h6>Grand Total</h6>
                </div>
                <div>
                  {currency} {orderDetail?.order_total - orderDetail?.commission_amount}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderList;
