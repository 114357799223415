import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import user from '../../assets/img/user.png'
import axios from 'axios';
import { apibaseURl, showSuccessToast, getvalidationErr, showErrorToast, tokengenerate, loginUserDetailkey, fileUpload } from '../../../Function'
import { AccountContext } from '../../adminContext/AccountProvider';
import Select from 'react-select'

const Profile = () => {
  const [passCheck1, setPassCheck1] = useState(false);
  const [passCheck2, setPassCheck2] = useState(false);
  const [passCheck3, setPassCheck3] = useState(false);

  const { setIstoken, setisprofile, countryList } = useContext(AccountContext)
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phonecode_country: "",
    phone: "",
    image: "",
    user_address: "",
  });
  const [inputpass, setInputpass] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [profileData, setprofileData] = useState([])
  const [account, setAccount] = useState("profile");
  const handleAccountProfile = (currentbox = "") => {
    setAccount(currentbox)
  }
  const handleCheck1 = () => {
    setPassCheck1(!passCheck1)
  }
  const handleCheck2 = () => {
    setPassCheck2(!passCheck2)
  }
  const handleCheck3 = () => {
    setPassCheck3(!passCheck3)
  }



  const options = countryList.map(country => ({
    value: country.code,
    label: `${country.iso3} (${country.phonecode})`
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#f0f1f3',
      fontSize: '14px',
      color: '#111111',
      borderRadius: '2px',
      border: `0px solid ${state.isFocused ? '#dddd' : '#dddd'}`, // Change border color on focus
      padding: '5px 15px',
      boxShadow: state.isFocused ? '0 0 0 0' : provided.boxShadow,
      transition: 'border-color 0.2s ease', // Add transition for smooth effect
      '&:hover': {
        border: `0px solid #dddd`, // Change border color on hover
      },
    }),
  };
  // image upload api call ============================>
  const handleFileUploadimage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(`${apibaseURl}upload`, formData,
        {
          headers: {
            Authorization: `Bearer ${tokengenerate()}`,
          },
        }
      );

      if (response.status === 200 && response.data.success === true) {
        setInputs((preInputs) => ({
          ...preInputs,
          image: response?.data?.data?.image
        }))
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response.data.msg);
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };
  // category listing show api call ===================>
  const fetchSingleUser = async () => {
    try {
      const response = await axios.get(`${apibaseURl}loginUserProfile`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status == 200 && response.data.success === true) {
  
        setInputs(response?.data?.data)
        setprofileData(response?.data?.data)
        setInputs((preInputs) => ({
          ...preInputs,
          image: response?.data?.data?.image
        }))
      } else {

      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  useEffect(() => {
    fetchSingleUser();
  }, [])
  // category listing show api call ===================>

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async (event) => {
    setisprofile(false)

    event.preventDefault();

    const editProfile = {
      name: inputs.name,
      email: inputs.email,
      phonecode_country: inputs.phonecode_country,
      phone: inputs.phone,
      user_address: inputs.user_address,
      image: inputs.image
    }



    try {
      const responce = await axios.put(`${apibaseURl}loginUserProfileUpdate`, editProfile, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      })


      if (responce.status == 200 && responce.data.success == true) {
        showSuccessToast(responce?.data?.message);
        const tokendata = {
          token: tokengenerate(),
          user: responce.data.data,
        };
        localStorage.setItem("winwinUser", JSON.stringify(tokendata));
        setisprofile(true)
        setInputs("")
        fetchSingleUser()
      } else {
        if (responce?.data?.errors && responce?.data?.errors.length > 0) {
          const errorMessages = getvalidationErr(responce?.data?.errors);
          showErrorToast(errorMessages);
        } else {
          showErrorToast(responce?.data?.message);
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  }

  // change Password api call ==============================>
  const handleChangePass = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputpass((values) => ({ ...values, [name]: value }));
  };


  const handleSubmitPass = async (event) => {
    event.preventDefault();
    const changepassword = {
      oldpassword: inputpass.oldpassword,
      newpassword: inputpass.newpassword,
      confirmpassword: inputpass.confirmpassword,
    };

    try {
      const responce = await axios.put(`${apibaseURl}ChangePasswordloginUser`, changepassword, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
      );


      if (responce.status == 200 && responce.data.success == true) {
        showSuccessToast(responce?.data?.message);
        setInputpass({
          oldpassword: "",
          newpassword: "",
          confirmpassword: "",
        })
      } else {
        if (responce?.data?.errors && responce?.data?.errors.length > 0) {
          const errorMessages = getvalidationErr(responce?.data?.errors);
          showErrorToast(errorMessages);
        } else {
          showErrorToast(responce?.data?.message);
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };



  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title">Profile</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Profile</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="profile-header">
              <div className="row align-items-center">
                <div className="col-auto profile-image">
                  <Link href="#">
                    {profileData && profileData.image ?
                      <img
                        className="rounded-circle"
                        alt="User"
                        src={fileUpload + profileData.image}
                      />
                      :
                      <img
                        className="rounded-circle"
                        alt="User"
                        src={user}
                      />
                    }

                  </Link>
                </div>
                <div className="col ms-md-n2 profile-user-info">
                  <span className="store_info">{profileData.name}</span><br />
                  <span className="store_info_location">{profileData.email}</span><br />
                  <span className="store_info_location">{profileData.phone}</span><br />
                  <div className="user-Location">
                    {profileData.user_address && (
                      <>
                        <i className="fas fa-map-marker-alt" /> {profileData.user_address}
                      </>
                    )}
                  </div>
                </div>

              </div>
            </div>
            <div className="profile-menu">
              <ul className="nav nav-tabs nav-tabs-solid">
                <li className="nav-item">
                  <Link className={`nav-link ${account === "profile" ? "active" : ""}`} onClick={() => handleAccountProfile("profile")}>Profile</Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${account === "password" ? "active" : ""}`} onClick={() => handleAccountProfile("password")}>Password</Link>
                </li>
              </ul>
            </div>
            <div className="mt-5">
              {account === "profile" && (
                <div className="tab-pane" id="per_details_tab">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title d-flex justify-content-between">
                            <span>Profile</span>
                          </h5>

                          <div className="row mt-5">
                            <div className="col-sm-12">
                              <form onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Name
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name='name'
                                        value={inputs.name}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Email <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        name='email'
                                        value={inputs.email}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>


                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Mobile <span className="login-danger"></span>
                                      </label>
                                      <div className='div_select_wrape'>
                                       
                                        <select
                                          style={{ width: '100%', padding: '8px' }}
                                          className='stylecomponenet'
                                          name='phonecode_country'
                                          value={inputs.phonecode_country}
                                          onChange={handleChange}
                                        >
                                          <option value="" disabled>Select Country</option>
                                          {countryList.map((country) => (
                                            <option key={country.code} value={country.iso}>
                                             {country.iso} (+{country.phonecode})
                                            </option>
                                          ))}
                                        </select>
                                        <input
                                          type="text"
                                          className="form-control from_control_phone"
                                          placeholder="Mobile"
                                          name='phone'
                                          value={inputs.phone}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>



                                  <div className="col-12 col-sm-12">
                                    {inputs.image &&
                                      <img
                                        className="rounded-circle"
                                        alt="User"
                                        src={fileUpload + inputs.image}
                                        width="100px"
                                        height="100px"
                                      />
                                    }
                                    <div className="form-group">
                                      <label>
                                        Image
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        placeholder="Email"
                                        name="imageUpload"
                                        onChange={handleFileUploadimage}
                                      />
                                    </div>
                                  </div>



                                  <div className="col-12 mt-3">
                                    <div className="form-group local-forms">
                                      <label>
                                        Address <span className="login-danger"></span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter address"
                                        name='user_address'
                                        value={inputs.user_address}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>


                                  <div className="col-12">
                                    <div className="Profile_submit">
                                      <button type="submit" className="btn btn Profile_submit_button">
                                        Submit
                                      </button>
                                    </div>
                                  </div>


                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              )}

              {account === "password" && (
                <div id="password_tab" className="tab-pane">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Change Password</h5>
                      <div className="row mt-4">
                        <div className="col-md-10 col-lg-12">
                          <form onSubmit={handleSubmitPass}>
                            <div className="form-group form_group_check">
                              <label>Old Password</label>
                              <input
                                type={passCheck1 ? "text" : "password"}
                                id='password'
                                className="form-control"
                                name='oldpassword'
                                value={inputpass.oldpassword}
                                onChange={handleChangePass}
                              />
                              <span className={`profile-views ${passCheck1 ? "feather-eye-off" : "feather-eye"} toggle-password`} onClick={handleCheck1} />
                            </div>

                            <div className="form-group form_group_check">
                              <label>New Password</label>
                              <input
                                type={passCheck2 ? "text" : "password"}
                                id='password'
                                className="form-control"
                                name='newpassword'
                                value={inputpass.newpassword}
                                onChange={handleChangePass}
                              />
                              <span className={`profile-views ${passCheck2 ? "feather-eye-off" : "feather-eye"} toggle-password`} onClick={handleCheck2} />
                            </div>



                            <div className="form-group form_group_check">
                              <label>Confirm Password</label>
                              <input
                                type={passCheck3 ? "text" : "password"}
                                id='password'
                                className="form-control"
                                name='confirmpassword'
                                value={inputpass.confirmpassword}
                                onChange={handleChangePass}
                              />
                              <span className={`profile-views ${passCheck3 ? "feather-eye-off" : "feather-eye"} toggle-password`} onClick={handleCheck3} />
                            </div>


                            <button type="submit" className="btn btn Profile_submit_button">
                              Save Changes
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Profile
