import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { apibaseURl, showErrorToast, tokengenerate, loaderFunction } from '../../../Function';
import Swal from "sweetalert2";
import { AccountContext } from '../../adminContext/AccountProvider';

const Inquery = () => {
  const { setIstoken } = useContext(AccountContext);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [supportQueriesList, setSupportQueriesList] = useState([]);
  const [enquirysingle, setenquirysingle] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleGetEnquiry = (item) => {
    setenquirysingle(item);
    handleShow();
  };

  // category listing show api call ===================>
  const fetchSupportQueries = async () => {
    try {
      const response = await axios.get(`${apibaseURl}AllSupportQueries`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      if (response.status === 200 && response.data.success === true) {
        setSupportQueriesList(response?.data?.data)
        setIsLoading(false)
      } else {
        setSupportQueriesList([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSupportQueries();
  }, [])

  // SupportQueries status update  api call here =================>
  const handleStatusUpdate = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to update this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      });

      if (result.isConfirmed) {
        const responce = await handleStatus(id);
        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
            fetchSupportQueries();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Updatd it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };
  const handleStatus = async (id) => {
    const reponce = await axios.get(`${apibaseURl}updateSupportStatus/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };

  // SupportQueries Read status update  api call here =================>
  const handleReadStatusUpdate = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to update this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      });

      if (result.isConfirmed) {
        const responce = await handleReadStatus(id);
  
        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
            fetchSupportQueries();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Updatd it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };
  const handleReadStatus = async (id) => {
    const reponce = await axios.get(`${apibaseURl}updateSupportReadStatus/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };

  // SupportQueries Read status update  api call here =================>
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await delesectionapicall(id);
 
        if (response.status === 200) {
          if (response.data.success === true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            fetchSupportQueries();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Delete it",
              text: response.message,
            });
          }
        }
      }
    } catch (error) {
      console.error("An error occurred in Delete Section:", error);
    }
  };

  const delesectionapicall = async (id) => {
    const response = await axios.delete(`${apibaseURl}DeleteSupportQueries/${id}`, {
      headers: {
        Authorization: `Bearer ${tokengenerate()}`,
      },
    });
    return response;
  };

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Support & Help Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">All Support Queries</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Support Queries List</h3>
                    </div>
                  </div>
                </div>
                {isLoading  ?
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                  :
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Subject</th>
                          <th>Read Status</th>
                          <th>Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supportQueriesList.length > 0 ? (
                          supportQueriesList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td className='white_space_break'>{item.email}</td>
                              <td>{item.subject}</td>
                              <td>
                                <button type="button" className={`btn btn-sm ${item.read_status == "read" ? "btn btn-success"
                                  : "btn-danger"
                                  } `} onClick={()=>handleReadStatusUpdate(item.id)}>
                                  {item.read_status == "unread" ? "Unread" : "Read"}
                                </button>
                              </td>
                              <td>
                                <button type="button" className={`btn btn-sm ${item.status == "completed" ? "btn btn-success"
                                  : "btn-danger"
                                  } `} onClick={()=>handleStatusUpdate(item.id)}>
                                  {item.status == "pending" ? "Pending" : "Completed"}
                                </button>
                              </td>
                              <td className="text-end">
                                <Link to="" className="btn btn-sm btn-danger store_add_wallet_add" onClick={()=>handleGetEnquiry(item)}>
                                  <i className="feather-eye" />&nbsp;
                                  View
                                </Link> &nbsp;
                                <Link to="" className="btn btn-sm btn-danger store_add_wallet_add_red" onClick={()=>handleDelete(item.id)}>
                                  <i className="feather-trash-2" />&nbsp;
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Details   
          &nbsp;<span class={`badge  bdge_app_css ${enquirysingle.read_status == "unread" ? "badge-danger" : "badge-success"}`}>{enquirysingle.read_status}</span>&nbsp;
          <span class={`badge  bdge_app_css ${enquirysingle.status == "pending" ? "badge-danger" : "badge-success"}`}>{enquirysingle.status}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              {/* <h4 className="mb-3">Details</h4> */}
              <ul className="list-unstyled list_style_query">
                <li><strong>Name:</strong> {enquirysingle.name}</li>
                <li><strong>Email:</strong> {enquirysingle.email}</li>
                <li><strong>Phone:</strong> {enquirysingle.phone}</li>
                <li><strong>Subject:</strong> {enquirysingle.subject}</li>
                <li><strong>Message:</strong> {enquirysingle.message}</li>
          
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Inquery;
