import React, { useContext } from 'react'
import logo from '../../../front/frontassest/images/logo.png'
import apple_store from '../../../front/frontassest/images/apple-store.png'
import play_store from '../../../front/frontassest/images/playstore.png'
import { Link } from 'react-router-dom';
import { fileUpload } from '../../../Function';
import { GlobalExportdata } from '../../../App';

const Footer = () => {

    const { globaldata } = useContext(GlobalExportdata)
    const termsUrl = 'terms-of-use'
    const policyUrl = 'privacypolicy'
    return (
        <>
            <footer className="ivo-section section ivo-footer pb-0 animate__fadeInUp wow animate__animated animate__delay-1s">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                            <div className="ivo-footer-logo">
                                <img
                                    src={globaldata?.SiteLogo ? fileUpload + globaldata.SiteLogo : logo}
                                    alt="Logo"
                                />
                                <p className="hero-para mt-4 text-white">
                                 {globaldata?.footer_abouts_text}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
                            <div className="ivo-ftco-footer-widget ">
                                <h4 className="mb-3">legal</h4>
                                <ul className="ivo-footer-ul">
                                    {/* <li>
                                        <Link to="" className="py-1 d-block">
                                            Cookie Policy
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to={`/${termsUrl}`} className="py-1 d-block">
                                            Terms of Use
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${policyUrl}`} className="py-1 d-block">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 mb-3 mt-4 mt-lg-0 mt-md-0">
                            <div className="footer-icons  ">
                                <h4 className="mb-3">Follows Us</h4>
                                <ul className="footer-icon-box">
                                    {globaldata?.facebookicon && (
                                        <li>
                                            <Link to={globaldata?.facebookicon} target='_blank'>
                                                <i className="f-icons fa-brands fa-facebook-f f-block" />
                                            </Link>
                                        </li>
                                    )}
                                    {globaldata?.linkedinicon && (
                                        <li>
                                            <Link to={globaldata?.linkedinicon} target='_blank'>
                                                <i className="f-icons f-block fa-brands fa-linkedin" />
                                            </Link>
                                        </li>
                                    )}

                                    {globaldata?.youtubicon && (
                                        <li>
                                            <Link to={globaldata?.youtubicon} target='_blank'>
                                                <i className="f-icons f-block fa-brands fa-youtube" />
                                            </Link>
                                        </li>
                                    )}

                                    {globaldata?.Instagramicon && (
                                        <li>
                                            <Link to={globaldata?.Instagramicon} target='_blank'>
                                                <i className="f-icons f-block fa-brands fa-instagram" />
                                            </Link>
                                        </li>

                                    )}

                                </ul>
                                <div className="mt-2 d-flex footer-buttons">
                                    <Link className="mr-2 me-2" to={globaldata?.app_store_link ?? '#'} target='_blank'>
                                        <img src={apple_store} alt="" />
                                    </Link>{" "}
                                    <Link to={globaldata?.google_play_store ?? '#'} target='_blank'>
                                        <img src={play_store} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ivo-bottom-footer  ">
                    <p className="ivo-para text-white">{globaldata?.footerText}</p>
                </div>
            </footer>
        </>
    )
}

export default Footer;
