import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import { apibaseURl, tokengenerate, formatDateToCustomString, showErrorToast, loaderFunction } from '../../../Function';
import { AccordionContext } from 'react-bootstrap';


const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setIstoken } = useContext(AccordionContext)
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1);   // Total pages state
  const [pageSize, setPageSize] = useState(100);
  // Fetch all reviews
  const fetchAllReviews = async (page = 1) => {
    try {
      const response = await axios.get(`${apibaseURl}Allreview`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        },
        params: {
          page,        // Current page
          pageSize     // Page size
        }
      });

      if (response.status === 200 && response.data.success === true) {
        setReviews(response?.data?.data);
        setTotalPages(response?.data?.totalPages);   // Set total pages
        setIsLoading(false)
      } else {
        setReviews([]);
        setTotalPages(1);
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message);
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchAllReviews();
  }, [currentPage, pageSize]);

  // Handle delete review
  // page status update  api call here =================>
  const handleStatusUpdatesubmit = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to update this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      });

      if (result.isConfirmed) {
        const responce = await handleStatus(id);

        if (responce.status === 200) {
          if (responce.data.success === true) {
            Swal.fire("Status Updated!", "Your Status has been Updated.", "success");
            fetchAllReviews();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Updatd it",
              text: responce.message,
            });
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status == 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status == 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    }
  };
  const handleStatus = async (id) => {
    const reponce = await axios.get(`${apibaseURl}updaterReviewStatus/${id}`,
      {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`,
        },
      }
    );
    return reponce;
  };
  // review delete api call here ============================>
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await delesectionapicall(id);

        if (response.status === 200) {
          if (response.data.success === true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            fetchAllReviews();
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry you Can't Delete it",
              text: response.message,
            });
          }
        }
      }
    } catch (error) {
      console.error("An error occurred in Delete Section:", error);
    }
  };

  const delesectionapicall = async (id) => {
    const response = await axios.delete(`${apibaseURl}DeleteReview/${id}`, {
      headers: {
        Authorization: `Bearer ${tokengenerate()}`,
      },
    });
    return response;
  };

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Reviews Manager</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Dashboard</li>
                <li className="breadcrumb-item active">All Reviews</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row gy-2">
                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label className='label_for_entryes'>
                          Show{" "}
                          <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            className="custom-select custom-select-sm form-control form-control-sm"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading ?
                  <div className='div_loader_set'>
                    {loaderFunction()}
                  </div>
                  :
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>S.No</th>
                          <th>Customer</th>
                          <th>Store</th>
                          <th>Item ID</th>
                          <th>Rating</th>
                          <th>Status</th>
                          <th>Created <br /> Date</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reviews.length > 0 ? (
                          reviews.map((item, index) => (
                            <tr key={index}>
                              <td>{(currentPage - 1) * pageSize + index + 1}</td>
                              <td>Id : {item.user_id}<br />
                                {item.name}
                              </td>

                              <td>Id : {item.store_id}<br />
                                {item.store_name}
                              </td>

                              <td>{item.item_id}</td>
                              <td>{item.rating}</td>
                              <td>
                                <button type="button" className={`btn btn-sm ${item.status == "approved" ? "btn btn-success"
                                  : "btn-danger"
                                  } `} onClick={() => handleStatusUpdatesubmit(item.id)}>

                                  {item.status == "approved" ? "Approved" : "Pending"}
                                </button>
                              </td>
                              <td>{formatDateToCustomString(item.created_at)}</td>
                              <td className="text-end">
                                <Link to="" className="btn btn-sm btn-danger store_add_wallet_add_red" onClick={() => handleDelete(item.id)}>
                                  <i className="feather-trash-2" />&nbsp;
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={8}>No Reviews Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>


                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='for_data_structure_for_pagination'>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>Previous</a>
                  </li>
                  {/* {[...Array(totalPages)].map((_, index) => (
                    <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                      <a className="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</a>
                    </li>
                  ))} */}
                  {/* {Array.from({ length: totalPages }).slice(Math.max(0, currentPage - 3), Math.max(5, currentPage + 2)).map((_, index) => {
                    const pageIndex = Math.max(0, currentPage - 3) + index + 1;
                    return (
                      <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                        <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                      </li>
                    );
                  })} */}
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, index) => {
                    const pageIndex = index + 1;
                    return (
                      <li className={`page-item ${currentPage === pageIndex ? 'active' : ''}`} key={pageIndex}>
                        <a className="page-link" onClick={() => setCurrentPage(pageIndex)}>{pageIndex}</a>
                      </li>
                    );
                  })}
                  {totalPages > 5 && (
                    <li className="page-item">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  {totalPages > 5 && (
                    <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                      <a className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</a>
                    </li>
                  )}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
