import React, { useContext, useEffect, useState } from 'react';
import users from '../../assets/img/users.png';
import category from '../../assets/img/category.png';
import support from '../../assets/img/support.png';
import amount from '../../assets/img/download.svg';
import commission from '../../assets/img/commission.png';
import store from '../../assets/img/store.svg';
import order from '../../assets/img/order.jpg';
import { Link } from 'react-router-dom';
import { AccountContext } from '../../adminContext/AccountProvider';
import axios from 'axios';
import { apibaseURl, showErrorToast, tokengenerate, loaderFunction } from '../../../Function'
import BarChartComponentRevenue from './BarChartComponentRevenue';
import BarChartComponentCommiSsion from './BarChartComponentCommiSsion';
import { GlobalExportdata } from '../../../App';

const Dashboard = () => {
  const [dashboard, setdashboard] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { setIstoken} = useContext(AccountContext)
  const { currency } = useContext(GlobalExportdata)

  // category listing show api call ===================>
  const fetchDashboard = async () => {
    try {
      const response = await axios.get(`${apibaseURl}dashboard`, {
        headers: {
          Authorization: `Bearer ${tokengenerate()}`
        }
      });

      
      if (response.status === 200 && response.data.success === true) {
        setdashboard(response?.data?.data)
        setIsLoading(false)
      } else {
        setdashboard({});
      }
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
        showErrorToast(error?.response?.data?.message)
        setIstoken(true)
      } else if (error.response && error.response.status === 500 && error.response.data.success === false) {
        showErrorToast("Server Error.");
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDashboard();
  }, [])

  return (
    <>

      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Welcome Admin!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">

        <div className='col-xl-6'>
            <div className="card bg-comman w-100">
            <h6 className='revenue_genarte_graph'>Revenue ({currency})</h6>
              <BarChartComponentRevenue dashboard={dashboard} currency={currency}/>
            </div>
          </div>

          <div className='col-xl-6'>
            <div className="card bg-comman w-100">
            <h6 className='revenue_genarte_graph'>Commission ({currency})</h6>
              <BarChartComponentCommiSsion dashboard={dashboard} currency={currency}/>
            </div>
          </div>
          {/* Revenew*/}
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
         
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Today’s Revenue</h6>
                      <h3>{currency} {dashboard.todayRevenue ?? "0"}</h3>

                    </div>
                    <div className="db-icon">
                      <img src={amount} alt="Dashboard" />
                    </div>
                  </div>
                </div>

            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
        
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Yesterday’s Revenue</h6>
                      <h3>{currency} {dashboard.YesterdayRevenue ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={amount} alt="Dashboard" />
                    </div>
                  </div>
                </div>
     
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
          
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Month’s Revenue</h6>
                      <h3>{currency} {dashboard.thisMonthRevenue ?? "0"}</h3>
                    </div><br />
                    <div className="db-icon">
                      <img src={amount} alt="Dashboard" />
                    </div>
                  </div>
                </div>

            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
         
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Year’s Revenue</h6>
                      <h3>{currency} {dashboard.thisYearRevenue ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={amount} alt="Dashboard" />
                    </div>
                  </div>
                </div>

            </div>
          </div>
          {/* Commission Section */}
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">

                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Today’s Commission</h6>
                      <h3>{currency} {dashboard.todayCommission ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={commission} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
   
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
    
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Yesterday’s Commission</h6>
                      <h3>{currency} {dashboard.YesterdayCommission ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={commission} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>

            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">

                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Month’s Commission</h6>
                      <h3>{currency} {dashboard.thisMonthCommission ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={commission} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>

            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
           
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Year’s Commission</h6>
                      <h3>{currency} {dashboard.thisYearCommission ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={commission} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>

            </div>
          </div>

          {/* Orders Section */}
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Today’s Orders</h6>
                      <h3>{dashboard.todayOrders ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Yesterday’s Orders</h6>
                      <h3>{dashboard.YesterdayOrders ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Month Orders</h6>
                      <h3>{dashboard.thisMonthOrders ?? "0"}</h3>
                    </div>&nbsp;
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/orders">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Year Orders</h6>
                      <h3>{dashboard.thisYearOrders ?? "0"}</h3>
                    </div>&nbsp;
                    <div className="db-icon">
                      <img src={order} alt="Dashboard" width="50px" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          {/* Stores Section */}
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/store">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Total Stores</h6>
                      <h3>{dashboard.totalStore ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={store} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/store">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Verified Stores</h6>
                      <h3>{dashboard.getTotalVerifiedStore ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={store} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/store">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Unverified Stores</h6>
                      <h3>{dashboard.getTotalUnVerifiedStore ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={store} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/store">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Month Stores</h6>
                      <h3>{dashboard.getThisMonthStore ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={store} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {/* Users Section */}
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/users">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Total Customer</h6>
                      <h3>{dashboard.total_customers ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={users} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/users">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Active Customer</h6>
                      <h3>{dashboard.total_active_customers ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={users} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/users">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>Inactive Customer</h6>
                      <h3>{dashboard.total_inactive_customers ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={users} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 d-flex">
            <div className="card bg-comman w-100">
              <Link to="/admin/users">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>This Month Customer</h6>
                      <h3>{dashboard.this_Month_customers ?? "0"}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={users} alt="Dashboard" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Dashboard;
